@let s = score();
@if (s > 0 && rating) {
  <img class="image" src="{{ rating.path }}" alt="{{ rating.label }}" />
  <div>{{ rating.label }}</div>
  <div class="score">
    (Avg. score {{ rating.score }})
    <app-info-hover>
      <p>
        Answers to the question "How likely are you to use a solution like this if it were available?" are converted to
        numbers to calculate an average. In the summary, smileys represent the averages categorized into the following
        intervals:
      </p>
      <ul>
        <li><img src="/rating/VeryUnlikely.png" alt="very unlikely" /> Very unlikely = 0 - 1.25</li>
        <li><img src="/rating/SomewhatUnlikely.png" alt="somewhat unlikely" /> Somewhat unlikely = 1.26 - 3.75</li>
        <li><img src="/rating/Neutral.png" alt="neutral" /> Neutral = 3.76 - 6.25</li>
        <li><img src="/rating/SomewhatLikely.png" alt="somewhat likely" /> Somewhat likely = 6.26 - 8.75</li>
        <li><img src="/rating/VeryLikely.png" alt="very likely" /> Very likely = 8.76 - 10</li>
      </ul>
    </app-info-hover>
  </div>
}
