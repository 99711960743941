<app-card class="header">
  <div>
    <app-ribbon type="idea" />
    <div class="title">Scheduling</div>
    <p><b>Are you always operating your fields in the most efficient order?</b></p>
    <p>
      With a scheduling tool our aim is to reduce transport and ineffective time with intelligent planning of field
      work. Based on distance between fields, capacity and local weather forecasts, our scheduling mechanism will come
      up with an optimized schedule for the fields you select.
    </p>
  </div>
  <div>
    <div class="subtitle">Have a look</div>
    <p>
      Take a look at the scenario that illustrates how a tool could be used to reduce transport and ineffective time.
    </p>
    <p>
      Examine the example schedule and consider if you would use a tool to support your planning, if it could optimize
      your field work.
    </p>
    <p class="info-feedback">
      ... and let us know what you think -
      <app-link target="_self" [href]="goToFeedback()">send your feedback</app-link>
    </p>
  </div>
</app-card>
<app-card>
  <app-card-title title="Imagine you could reduce transport and idle time" subtitle="" />
  <app-steps [steps]="steps" />
</app-card>
<app-card class="schedule">
  <app-card-title title="Schedule Example" subtitle="" />
  <div class="image-wrapper">
    <img src="images/SCH_example.png" alt="Example" />
  </div>
</app-card>
<app-card id="feedback">
  <app-feedback-card feature="Scheduling" />
</app-card>
