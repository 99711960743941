<app-card class="header">
  <div>
    <app-ribbon type="idea" />
    <div class="title"><img src="icons/path.svg" alt="Icon" /> Soil Compaction</div>
    <p><b>Investigate how you can reduce soil compaction with intelligent route planning</b></p>
    <p>
      In this project the goal is to use intelligent route planning to minimize soil compaction. You provide information
      about the operation, machinery and tire configuration, and the tool will come up with route suggestions optimized
      according to various parameters like time, fuel consumption and soil compaction. You can then simply choose the
      route that best suits your needs.
    </p>
  </div>
  <div>
    <div class="subtitle">Have a look</div>
    <p>
      Take a look at the scenario that illustrates how a tool could be used to reduce soil compaction. Reflect on how it
      would fit into your daily work.
    </p>
    <p>Examine the route suggestion examples and consider which one you would choose if it was your field.</p>
    <p class="info-feedback">
      ... and let us know what you think -
      <app-link target="_self" [href]="goToFeedback()">send your feedback</app-link>
    </p>
  </div>
</app-card>
<app-card>
  <app-card-title title="Imagine you could minimize soil compaction" subtitle="" />
  <app-steps [steps]="steps" />
</app-card>
<div class="routes">
  <app-card>
    <app-card-title title="Route suggestion 1" subtitle="Minimizing time" />
    <app-metrics [metrics]="metrics.time" />
    <img src="images/SC_example.png" alt="Example" />
  </app-card>
  <app-card>
    <app-card-title title="Route suggestion 2" subtitle="Minimizing soil compaction" />
    <app-metrics [metrics]="metrics.compaction" />
    <img src="images/SC_example.png" alt="Example" />
  </app-card>
</div>
<app-card id="feedback">
  <app-feedback-card feature="Soil Compaction" />
</app-card>
