import { Component, input } from '@angular/core';

@Component({
  selector: 'app-feedback-title',
  standalone: true,
  imports: [],
  templateUrl: './feedback-title.component.html',
  styleUrl: './feedback-title.component.css',
})
export class FeedbackTitleComponent {
  public title = input.required<string>();
  public subtitle = input<string>();
}
