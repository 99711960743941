import { Component } from '@angular/core';
import { ButtonComponent } from '../../components/button/button.component';
import { BulletComponent } from '../../components/bullet/bullet.component';
import { LinkComponent } from '../../components/link/link.component';
import { ModalService } from '../../state/modal/modal.service';
import { EulaViewComponent } from '../../layout/components/eula-view/eula-view.component';

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [ButtonComponent, BulletComponent, LinkComponent],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.css',
})
export class LandingPageComponent {
  constructor(public modalService: ModalService) {}

  public doSignUp() {
    this.modalService.openModal(EulaViewComponent);
  }
}
