<div class="metric-card">
  <div class="title">Metrics</div>
  <div class="card-wrapper">
    @for (metric of metrics(); track metric.label) {
      <div class="card">
        <div>{{ metric.label }}</div>
        <div class="value">{{ metric.value }}</div>
      </div>
    }
  </div>
</div>
