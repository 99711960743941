@let d = data();
<app-card>
  <h3>{{ d.title }}</h3>
  <p>{{ d.subtitle }}</p>
  <div class="content">
    <app-response-score [score]="d.score" />
    <div>
      <div class="count">{{ d.count }}</div>
      <div class="comments">{{ d.comments }}</div>
      <div class="recent">{{ d.recent }}</div>
    </div>
  </div>
  <app-link [routerLink]="d.link">{{ d.linkLabel }}</app-link>
</app-card>
