import { Component, input } from '@angular/core';
import { CardComponent } from '../card/card.component';
import { LinkComponent } from '../link/link.component';
import { RibbonComponent } from '../ribbon/ribbon.component';
import { NgTemplateOutlet } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AnalyticsService } from '../../state/analytics/analytics.service';
import { externalClick } from '../../utils/url/link-tracker';

export type RibbonType = 'beta' | 'idea' | 'prototype';

@Component({
  selector: 'app-feature-card',
  standalone: true,
  imports: [CardComponent, LinkComponent, RibbonComponent, NgTemplateOutlet, RouterModule],
  templateUrl: './feature-card.component.html',
  styleUrl: './feature-card.component.css',
})
export class FeatureCardComponent {
  public icon = input<string>();
  public action = input<{ href: string; label: string }>();
  public ribbon = input<RibbonType>();
  public img = input<string>();

  constructor(private analytics: AnalyticsService) {}

  isExternalLink(link: string): boolean {
    return /^(http|https):/.test(link);
  }

  async externalClick(e: MouseEvent) {
    return externalClick(e, this.action()?.href || '', this.analytics);
  }
}
