import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BackendService } from '../../../api/bff/backend.service';
import { AnalyticsFormComponent } from './analytics-form/analytics-form.component';
import { AnalyticsData } from '@shared/interfaces/analytics';
import { AnalyticsStatsComponent } from './analytics-stats/analytics-stats.component';
import { AnalyticsVisitorsComponent } from './analytics-visitors/analytics-visitors.component';
import { AnalyticsPageViewsComponent } from './analytics-page-views/analytics-page-views.component';
import { AnalyticsPageUsageComponent } from './analytics-page-usage/analytics-page-usage.component';
import { CardComponent } from '../../../components/card/card.component';

@Component({
  selector: 'app-analytics',
  standalone: true,
  imports: [
    AnalyticsFormComponent,
    AnalyticsStatsComponent,
    AnalyticsVisitorsComponent,
    AnalyticsPageViewsComponent,
    AnalyticsPageUsageComponent,
    CardComponent,
  ],
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.css',
})
export class AnalyticsComponent implements OnInit {
  protected data: AnalyticsData | null = null;
  protected period = '';

  constructor(private backend: BackendService) {}

  async ngOnInit() {
    this.data = await firstValueFrom(this.backend.getAnalytics(30));
    this.setPeriod(30);
  }

  protected async onPeriodChange(period: number) {
    this.data = await firstValueFrom(this.backend.getAnalytics(period));
    this.setPeriod(period);
  }

  private setPeriod(period: number) {
    if (period === 0) {
      this.period = 'Since launch';
    } else if (period === 30) {
      this.period = 'Last 30 days';
    } else {
      this.period = `Last 12 months`;
    }
  }
}
