import { Pipe, PipeTransform } from '@angular/core';
import { FeatureType } from '@shared/interfaces/feedback';
import { getFeatureUrl } from '@shared/utils/featureMapper';

@Pipe({
  name: 'urlEncode',
  standalone: true,
})
export class UrlEncodePipe implements PipeTransform {
  transform(value: FeatureType): string {
    return getFeatureUrl(value);
  }
}
