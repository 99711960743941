import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../state/auth/auth.service'; // Replace with your auth service path

@Injectable({
  providedIn: 'root',
})
export class AutoLoginGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  async canActivate(): Promise<boolean> {
    if (!(await this.authService.user)) {
      this.authService.autoSignIn();
    } else {
      this.router.navigate(['/home']);
    }
    return true;
  }
}
