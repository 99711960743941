import { Component, input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FeedbackTitleComponent } from '../feedback-title/feedback-title.component';
import { QuestionItem } from '../../feedback-types';

@Component({
  selector: 'app-feedback-radio',
  standalone: true,
  imports: [ReactiveFormsModule, FeedbackTitleComponent],
  templateUrl: './feedback-radio.component.html',
  styleUrl: './feedback-radio.component.css',
})
export class FeedbackRadioComponent implements OnInit {
  public question = input.required<QuestionItem>();
  protected form!: FormGroup;
  protected additionalInfo = '';

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }

  updateAdditionalInfo(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.additionalInfo = inputElement.value;
    const control = this.form.get(this.question().title);
    const wildcard = this.question().wildcard;
    if (control && wildcard) {
      control.setValue(wildcard.label + ' ' + this.additionalInfo);
    }
  }
}
