import { FeedbackFormData } from '../../components/feedback-form/feedback-types';

export const osmFormData: FeedbackFormData = {
  title: "We'd like to hear some more",
  subtitle: 'We promise it will only take a minute of your time, and your answers are of high value for us',
  questions: [
    [
      {
        title: 'Some question like "I can use this"',
        type: 'radio',
        options: ['Yes', 'No'],
      },
      {
        title: 'Some question like "How can you use it"',
        type: 'textarea',
      },
      {
        title: 'Some question like "What do you find most valuable"',
        type: 'textarea',
      },
    ],
    [
      {
        title: 'Additional comments',
        subtitle: 'If anything missing, do you have suggestions',
        type: 'textarea',
      },
      {
        title: 'E-mail contact',
        subtitle:
          'In case we need more information, we would like to contact you on the e-mail address you gave us when signing up.',
        type: 'consent',
        options: ['By ticking this box you allow us to get in contact with you by e-mail.'],
      },
    ],
  ],
};
