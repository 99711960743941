import { NgStyle } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-feedback-indicator',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './feedback-indicator.component.html',
  styleUrl: './feedback-indicator.component.css',
})
export class FeedbackIndicatorComponent {
  public progress = input.required<number>();
  public total = input.required<number>();

  getWidth() {
    const progress = (this.progress() / this.total()) * 100;
    return `${progress === 0 ? 5 : progress}%`;
  }
}
