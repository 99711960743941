import { Component, input } from '@angular/core';
import { CardComponent } from '../card/card.component';
import { RibbonComponent, RibbonType } from '../ribbon/ribbon.component';
import { NgTemplateOutlet } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-feature-card',
  standalone: true,
  imports: [CardComponent, RibbonComponent, NgTemplateOutlet, RouterModule],
  templateUrl: './feature-card.component.html',
  styleUrl: './feature-card.component.css',
})
export class FeatureCardComponent {
  public icon = input<string>();
  public action = input<{ href: string; label: string }>();
  public ribbon = input<RibbonType>();
  public img = input<string>();

  isExternalLink(link: string): boolean {
    return /^(http|https):/.test(link);
  }

  async externalClick(_: MouseEvent) {
    return this.action()?.href || '';
  }
}
