import { Component, input, OnInit } from '@angular/core';
import { QuestionItem } from '../../feedback-types';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FeedbackTitleComponent } from '../feedback-title/feedback-title.component';

@Component({
  selector: 'app-feedback-pricing',
  standalone: true,
  imports: [ReactiveFormsModule, FeedbackTitleComponent],
  templateUrl: './feedback-pricing.component.html',
  styleUrl: './feedback-pricing.component.css',
})
export class FeedbackPricingComponent implements OnInit {
  public question = input.required<QuestionItem>();
  protected form!: FormGroup;
  protected currency = '€';

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }

  currencyChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.id === 'eur') {
      this.currency = '€';
    } else {
      this.currency = '$';
    }
  }
}
