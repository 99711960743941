import { Component, input } from '@angular/core';
import { LinkComponent } from '../../../../../components/link/link.component';
import { CardComponent } from '../../../../../components/card/card.component';
import { ResponseScoreComponent } from '../response-score/response-score.component';
import { InfoHoverComponent } from '../../../../../components/info-hover/info-hover.component';

export interface ResponseCardData {
  title: string;
  subtitle: string;
  count: number;
  comments: string;
  recent: string;
  link: string;
  linkLabel: string;
  score: number;
}
@Component({
  selector: 'app-response-card',
  standalone: true,
  imports: [CardComponent, LinkComponent, ResponseScoreComponent, InfoHoverComponent],
  templateUrl: './response-card.component.html',
  styleUrl: './response-card.component.css',
})
export class ResponseCardComponent {
  public data = input.required<ResponseCardData>();
}
