<div class="main">
  <div class="left">
    <app-card><app-welcome /></app-card>
    <app-card><app-feature-info /></app-card>
    <app-card><app-general-feedback /></app-card>
  </div>
  <div class="right">
    @for (card of featureCards; track card) {
      <app-feature-card [icon]="card.icon" [action]="card.action" [ribbon]="card.ribbon" [img]="card.image">
        <h2 card-title>{{ card.title }}</h2>
        <div card-body>
          <p>{{ card.content }}</p>
        </div>
      </app-feature-card>
    }
  </div>
</div>
