@if (routerLink()) {
  <a [class]="variant()" [routerLink]="routerLink()"><ng-container [ngTemplateOutlet]="hrefLink"></ng-container></a>
} @else {
  <a [class]="variant()" [href]="href()" [target]="target()" (click)="externalClick($event)">
    <ng-container [ngTemplateOutlet]="hrefLink"></ng-container>
  </a>
}

<ng-template #hrefLink>
  <ng-content />
</ng-template>
