import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { AnalyticsData, BackendServiceInterface } from './backend.service.interface';
import { FeatureType, Feedback, FeedbackCreate, FeedbackStatistic } from '@shared/interfaces/feedback';
import { User } from '@shared/interfaces/user';

@Injectable({ providedIn: 'root' })
export class BackendService implements BackendServiceInterface {
  constructor(private httpClient: HttpClient) {}

  getHealth(): Observable<string> {
    return this.httpClient.get<string>(`${environment.backend}/health`);
  }

  getUser(): Observable<User> {
    return this.httpClient.get<User>(`${environment.backend}/user`);
  }

  putUser(user: User): Observable<User> {
    return this.httpClient.put<User>(`${environment.backend}/user`, user);
  }

  randomData = () => {
    const randomData: { [key: string]: number } = {};
    for (let day = 1; day <= 31; day++) {
      const date = `07-${String(day).padStart(2, '0')}`;
      randomData[date] = Math.floor(Math.random() * 1001);
    }
    return randomData;
  };
  // TODO: Implement real call;
  getAnalytics(): Observable<AnalyticsData> {
    return of({
      visitors: {
        byCountry: {
          'United States of America': 100,
          Canada: 200,
          Denmark: 300,
          Germany: 400,
          France: 500,
          Italy: 600,
          Spain: 700,
          Japan: 800,
          Australia: 900,
          Brazil: 1000,
          India: 1100,
          China: 1200,
          Russia: 1300,
          'South Africa': 1400,
          Mexico: 1500,
          Argentina: 1600,
          'United Kingdom': 1700,
          'South Korea': 1800,
          'Saudi Arabia': 1900,
          'New Zealand': 2000,
          Netherlands: 2100,
          Sweden: 2200,
          Norway: 2300,
          Switzerland: 2400,
          Turkey: 2500,
        },
        byDate: this.randomData(),
      },
    });
  }

  getFeedback(): Observable<Feedback[]> {
    return this.httpClient.get<Feedback[]>(`${environment.backend}/feedback`);
  }

  getFeedbackStatistics(): Observable<FeedbackStatistic[]> {
    return this.httpClient.get<FeedbackStatistic[]>(`${environment.backend}/feedback/statistics`);
  }

  getFeedbackByFeature(feature: FeatureType): Observable<Feedback[]> {
    return this.httpClient.get<Feedback[]>(`${environment.backend}/feedback/feature/${feature}`);
  }

  postFeedback(data: FeedbackCreate): Observable<Feedback> {
    return this.httpClient.post<Feedback>(`${environment.backend}/feedback`, data);
  }
}
