import { Component } from '@angular/core';
import { ButtonComponent } from '../../../components/button/button.component';
import { AuthService } from '../../../state/auth/auth.service';
import { ModalService } from '../../../state/modal/modal.service';
import { ProfileViewComponent } from '../profile-view/profile-view.component';

@Component({
  selector: 'app-header-menu',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './header-menu.component.html',
  styleUrl: './header-menu.component.css',
})
export class HeaderMenuComponent {
  constructor(
    private authService: AuthService,
    private modalService: ModalService,
  ) {}

  showProfile() {
    this.modalService.openModal(ProfileViewComponent);
  }

  logout() {
    this.authService.signOut();
  }
}
