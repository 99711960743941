import { OSMResponse } from './portal.service.interface';

export const OSM_RESPONSE_MOCK: OSMResponse = {
  features: [
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1058679, 56.3671679],
            [10.1058337, 56.367153],
            [10.1057937, 56.3671435],
            [10.1057506, 56.36714],
            [10.1057074, 56.3671429],
            [10.105667, 56.3671518],
            [10.1056321, 56.3671663],
            [10.1056052, 56.3671852],
            [10.1055881, 56.3672074],
            [10.1055819, 56.3672312],
            [10.1055871, 56.3672552],
            [10.1056032, 56.3672775],
            [10.1056293, 56.3672968],
            [10.1056635, 56.3673117],
            [10.1057035, 56.3673212],
            [10.1057466, 56.3673247],
            [10.1057898, 56.3673218],
            [10.1058302, 56.3673128],
            [10.105865, 56.3672984],
            [10.1058919, 56.3672795],
            [10.1059091, 56.3672573],
            [10.1059153, 56.3672335],
            [10.1059101, 56.3672095],
            [10.1058939, 56.3671871],
            [10.1058679, 56.3671679],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-1',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1056322, 56.3673554],
            [10.105593, 56.3673448],
            [10.1055503, 56.3673403],
            [10.1055068, 56.367342],
            [10.1054657, 56.3673499],
            [10.1054296, 56.3673634],
            [10.1054011, 56.3673816],
            [10.1053821, 56.3674033],
            [10.1053739, 56.367427],
            [10.105377, 56.3674511],
            [10.1053912, 56.3674739],
            [10.1054157, 56.3674938],
            [10.1054486, 56.3675096],
            [10.1054877, 56.3675202],
            [10.1055305, 56.3675247],
            [10.1055739, 56.367523],
            [10.1056151, 56.3675151],
            [10.1056511, 56.3675016],
            [10.1056797, 56.3674833],
            [10.1056987, 56.3674616],
            [10.1057069, 56.367438],
            [10.1057038, 56.3674139],
            [10.1056895, 56.3673911],
            [10.1056651, 56.3673711],
            [10.1056322, 56.3673554],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-2',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1054389, 56.367563],
            [10.1054032, 56.3675489],
            [10.1053621, 56.3675404],
            [10.1053184, 56.367538],
            [10.1052751, 56.367542],
            [10.1052351, 56.3675521],
            [10.1052012, 56.3675675],
            [10.1051757, 56.3675873],
            [10.1051603, 56.3676101],
            [10.1051561, 56.3676343],
            [10.1051633, 56.3676582],
            [10.1051814, 56.3676804],
            [10.1052093, 56.3676992],
            [10.105245, 56.3677133],
            [10.1052861, 56.3677218],
            [10.1053298, 56.3677242],
            [10.1053731, 56.3677202],
            [10.1054131, 56.3677101],
            [10.105447, 56.3676947],
            [10.1054725, 56.3676749],
            [10.1054879, 56.3676521],
            [10.1054922, 56.3676279],
            [10.1054849, 56.3676039],
            [10.1054668, 56.3675818],
            [10.1054389, 56.367563],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-3',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1033137, 56.3661385],
            [10.1033578, 56.3662097],
            [10.103396, 56.3662025],
            [10.1033518, 56.3661312],
            [10.1033137, 56.3661385],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-4',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1068952, 56.3705899],
            [10.1069554, 56.370589],
            [10.1069531, 56.3705503],
            [10.1068931, 56.3705514],
            [10.1068952, 56.3705899],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-5',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1069139, 56.3706156],
            [10.1069786, 56.3706456],
            [10.1070075, 56.3706264],
            [10.1069428, 56.3705964],
            [10.1069139, 56.3706156],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-6',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1074406, 56.3698886],
            [10.1070482, 56.369865],
            [10.1070295, 56.3699611],
            [10.1071596, 56.369969],
            [10.1071535, 56.3699996],
            [10.1072829, 56.3700073],
            [10.107289, 56.3699759],
            [10.1074219, 56.3699839],
            [10.1074406, 56.3698886],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-7',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1066749, 56.3706199],
            [10.1064261, 56.3705722],
            [10.1063872, 56.3706347],
            [10.1063587, 56.3706292],
            [10.1063441, 56.3706527],
            [10.1064301, 56.3706692],
            [10.1064386, 56.3706557],
            [10.1066297, 56.3706924],
            [10.1066749, 56.3706199],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-8',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1071328, 56.3694934],
            [10.1074969, 56.3695147],
            [10.1074355, 56.3698385],
            [10.107605, 56.3698484],
            [10.1076857, 56.3694221],
            [10.107013, 56.3693828],
            [10.1069324, 56.3698082],
            [10.1070718, 56.3698163],
            [10.1071328, 56.3694934],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-9',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1076159, 56.3699236],
            [10.1075956, 56.3700148],
            [10.1076652, 56.3700196],
            [10.1076855, 56.3699284],
            [10.1076159, 56.3699236],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-10',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1114535, 56.3705635],
            [10.1114354, 56.3705946],
            [10.1113723, 56.3705833],
            [10.1113567, 56.37061],
            [10.1112954, 56.370599],
            [10.1112263, 56.3707181],
            [10.1113658, 56.370743],
            [10.1114132, 56.3706612],
            [10.1115512, 56.3706881],
            [10.1116062, 56.3705932],
            [10.1114535, 56.3705635],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-11',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1039314, 56.3662093],
            [10.1039841, 56.3663493],
            [10.1045303, 56.3662866],
            [10.1044852, 56.3661449],
            [10.1039314, 56.3662093],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-12',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1051175, 56.3665464],
            [10.1051923, 56.3664737],
            [10.1048327, 56.3663598],
            [10.1047579, 56.3664324],
            [10.1051175, 56.3665464],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-13',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1048616, 56.3669323],
            [10.1048726, 56.3669217],
            [10.1052752, 56.3670506],
            [10.1053718, 56.3669578],
            [10.1050388, 56.3668511],
            [10.1050752, 56.3668161],
            [10.1056918, 56.3670137],
            [10.105824, 56.3668865],
            [10.1051635, 56.366675],
            [10.1051571, 56.3666812],
            [10.1049655, 56.3666199],
            [10.1048446, 56.3667362],
            [10.1049175, 56.3667596],
            [10.1048737, 56.3668016],
            [10.1045311, 56.366693],
            [10.104362, 56.3668637],
            [10.1044523, 56.3668926],
            [10.1045322, 56.3668159],
            [10.1048122, 56.3669056],
            [10.1048015, 56.3669158],
            [10.1047037, 56.3668845],
            [10.1045384, 56.3670434],
            [10.1046481, 56.3670785],
            [10.1044658, 56.3672537],
            [10.1050634, 56.3674452],
            [10.1052301, 56.367285],
            [10.1046748, 56.3671071],
            [10.1047703, 56.3670153],
            [10.1048049, 56.3670264],
            [10.1047669, 56.3670629],
            [10.1052624, 56.3672215],
            [10.1053608, 56.3671269],
            [10.1048845, 56.3669742],
            [10.1049114, 56.3669483],
            [10.1048616, 56.3669323],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-14',
    },
    {
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [10.1035889, 56.3672024],
            [10.103941, 56.3671461],
            [10.1036175, 56.3665234],
            [10.1032654, 56.3665797],
            [10.1035889, 56.3672024],
          ],
        ],
      },
      properties: {
        designator: null,
        type: 'Building',
      },
      id: 'PLN-15',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.0974857, 56.3586494],
          [10.0988816, 56.3586846],
          [10.1002557, 56.3587818],
          [10.1012616, 56.35884],
          [10.1039189, 56.358925],
          [10.1042345, 56.3589529],
          [10.1045939, 56.3590088],
          [10.1115488, 56.3606201],
          [10.1116588, 56.3606502],
          [10.1125045, 56.3608237],
          [10.1135056, 56.3609902],
          [10.1144539, 56.3610984],
          [10.115983, 56.3611775],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-340',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.1002557, 56.3587818],
          [10.098768, 56.362696],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-341',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.0733744, 56.3620065],
          [10.0734658, 56.3618182],
          [10.07367, 56.36142],
          [10.0737776, 56.3612876],
          [10.073889, 56.3612221],
          [10.0751334, 56.361088],
          [10.0769341, 56.3607335],
          [10.0782554, 56.3605662],
          [10.0785431, 56.3604709],
          [10.0818055, 56.3583612],
          [10.0824103, 56.358014],
          [10.0837164, 56.3573947],
          [10.0839509, 56.3573267],
          [10.0841745, 56.3573073],
          [10.0850165, 56.357268],
          [10.0860197, 56.3572211],
          [10.0863594, 56.3572478],
          [10.0866136, 56.3572988],
          [10.0868141, 56.3573673],
          [10.0873922, 56.3575648],
          [10.0879284, 56.357748],
          [10.0882922, 56.357805],
          [10.0907016, 56.3579534],
          [10.0910518, 56.3580023],
          [10.0925989, 56.3583407],
          [10.0929832, 56.3583888],
          [10.0960638, 56.3584798],
          [10.0974857, 56.3586494],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-342',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.1002161, 56.3850938],
          [10.1001115, 56.3850129],
          [10.0997802, 56.384528],
          [10.0992953, 56.3837157],
          [10.0991392, 56.3834277],
          [10.099107, 56.3832822],
          [10.0990882, 56.3831352],
          [10.0991257, 56.383],
          [10.1012165, 56.3783184],
          [10.1013855, 56.3777065],
          [10.1015169, 56.3774792],
          [10.1019273, 56.3771258],
          [10.1020426, 56.3769802],
          [10.1020936, 56.376848],
          [10.1020829, 56.3766772],
          [10.1018898, 56.3757994],
          [10.1018817, 56.3756049],
          [10.1019541, 56.3753346],
          [10.1020587, 56.3751043],
          [10.102217, 56.3748682],
          [10.1030202, 56.3741362],
          [10.1030732, 56.374088],
          [10.1032014, 56.3739711],
          [10.1034079, 56.3737037],
          [10.1036868, 56.3731303],
          [10.1037742, 56.3729507],
          [10.10502, 56.3701122],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-343',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.10502, 56.3701122],
          [10.1049999, 56.3699828],
          [10.1038547, 56.3679422],
          [10.1038251, 56.3677982],
          [10.1038679, 56.3676658],
          [10.1040423, 56.3674801],
          [10.1042838, 56.3673079],
          [10.1044071, 56.3671297],
          [10.1043212, 56.3669646],
          [10.1041844, 56.3667907],
          [10.1042515, 56.3666645],
          [10.104391, 56.3666184],
          [10.1046485, 56.3666348],
          [10.1047531, 56.3665991],
          [10.1048228, 56.3665738],
          [10.104965, 56.3665711],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-344',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.1091502, 56.3679109],
          [10.104965, 56.3665711],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-345',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.1116588, 56.3606502],
          [10.1091502, 56.3679109],
          [10.1088588, 56.368764],
          [10.1088185, 56.3689872],
          [10.1088278, 56.3692326],
          [10.1089022, 56.3694867],
          [10.1089324, 56.369538],
          [10.109051, 56.369739],
          [10.1092834, 56.3699879],
          [10.1097057, 56.370317],
          [10.1098157, 56.3704544],
          [10.1098304, 56.3705806],
          [10.1098009, 56.3706772],
          [10.1096896, 56.3708161],
          [10.10953, 56.3709082],
          [10.1093175, 56.3709782],
          [10.1082111, 56.3711602],
          [10.1080282, 56.3711413],
          [10.1078051, 56.3710829],
          [10.106815, 56.3706248],
          [10.1065467, 56.3705387],
          [10.10502, 56.3701122],
          [10.1043815, 56.3699319],
          [10.1037586, 56.3697191],
          [10.1021346, 56.3689587],
          [10.1003929, 56.3682876],
          [10.0994848, 56.3678516],
          [10.0972004, 56.3666353],
          [10.0967643, 56.3664168],
          [10.0964882, 56.3663185],
          [10.0961858, 56.3662651],
          [10.0941171, 56.3660272],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-346',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.1123145, 56.35236],
          [10.1124054, 56.3525114],
          [10.1125127, 56.3527566],
          [10.1125449, 56.3529647],
          [10.1125449, 56.353192],
          [10.1119977, 56.35624],
          [10.1114452, 56.3586213],
          [10.1114438, 56.3588315],
          [10.1115029, 56.3589638],
          [10.1118368, 56.3594764],
          [10.1118945, 56.3596517],
          [10.1118542, 56.3598115],
          [10.1115488, 56.3606201],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-347',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.098768, 56.362696],
          [10.0972004, 56.3666353],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-348',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.106815, 56.3706248],
          [10.1068914, 56.3698717],
          [10.1069907, 56.369842],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-349',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.1136715, 56.3376453],
          [10.1128405, 56.3385985],
          [10.1099179, 56.3419505],
          [10.1093083, 56.3426803],
          [10.1076565, 56.3445851],
          [10.1067003, 56.3456878],
          [10.1044076, 56.3483314],
          [10.102659, 56.3503709],
          [10.1000151, 56.3534436],
          [10.0997824, 56.353714],
          [10.0993361, 56.3542944],
          [10.0990045, 56.3548319],
          [10.0987349, 56.3554208],
          [10.0987235, 56.3554503],
          [10.0974857, 56.3586494],
          [10.0955985, 56.3635485],
          [10.0952084, 56.3643375],
          [10.094735, 56.3651678],
          [10.0941171, 56.3660272],
          [10.0930848, 56.3674037],
          [10.0890975, 56.3727766],
          [10.0878814, 56.3744151],
          [10.0862315, 56.376659],
          [10.0859247, 56.3771772],
          [10.0857846, 56.377429],
          [10.0856692, 56.3776715],
          [10.0854632, 56.3782682],
          [10.0852562, 56.3791458],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-350',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.1089324, 56.369538],
          [10.108771, 56.3695554],
          [10.1077867, 56.3695078],
          [10.107725, 56.3695301],
          [10.1076955, 56.3695925],
          [10.1076579, 56.369845],
          [10.1076284, 56.3698629],
          [10.1075667, 56.3698688],
          [10.1069907, 56.369842],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-351',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.0965271, 56.3663936],
          [10.0969569, 56.3690815],
          [10.0970467, 56.3696428],
          [10.096962, 56.3697027],
          [10.0966874, 56.369897],
          [10.0967624, 56.3699419],
          [10.0968075, 56.3699876],
          [10.0968152, 56.3700426],
          [10.0968671, 56.3701034],
          [10.0968859, 56.3701752],
          [10.0969174, 56.3703113],
          [10.0967425, 56.3704197],
          [10.0965604, 56.3705434],
          [10.0964598, 56.3706929],
          [10.0964495, 56.3707083],
          [10.0963591, 56.3707412],
          [10.0961593, 56.3707872],
          [10.0959467, 56.3708727],
          [10.095809, 56.3709656],
          [10.0954482, 56.3711784],
          [10.0954322, 56.3711913],
          [10.0952563, 56.3713331],
          [10.0947637, 56.3716537],
          [10.0942008, 56.3720847],
          [10.0935213, 56.3725744],
          [10.0932109, 56.3727497],
          [10.0929547, 56.3728109],
          [10.0926903, 56.3729823],
          [10.0924045, 56.373203],
          [10.0919829, 56.373481],
          [10.0916733, 56.3736555],
          [10.0918252, 56.3738657],
          [10.0917597, 56.3738993],
          [10.0915655, 56.3739407],
          [10.0914423, 56.3739503],
          [10.0913552, 56.3740431],
          [10.0913237, 56.3741108],
          [10.0911882, 56.3741709],
          [10.0911078, 56.3741846],
          [10.0909504, 56.3742938],
          [10.0907618, 56.3743136],
          [10.0907012, 56.3743769],
          [10.0905231, 56.3744586],
          [10.0905835, 56.3745403],
          [10.0905363, 56.3745997],
          [10.0903469, 56.3746447],
          [10.0902298, 56.3746746],
          [10.090018, 56.3747395],
          [10.0900305, 56.3748678],
          [10.0899587, 56.3749359],
          [10.0898709, 56.3750388],
          [10.0898295, 56.3750789],
          [10.0897322, 56.3751154],
          [10.0897322, 56.3751426],
          [10.089625, 56.3751591],
          [10.089596, 56.375195],
          [10.0895035, 56.3751848],
          [10.0892332, 56.3752789],
          [10.0892432, 56.3753361],
          [10.0891841, 56.3754136],
          [10.0891343, 56.3755473],
          [10.0890641, 56.3756472],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-352',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.0972543, 56.3698358],
          [10.096962, 56.3697027],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-353',
    },
    {
      geometry: {
        type: 'LineString',
        coordinates: [
          [10.0992755, 56.3706941],
          [10.0983977, 56.3704651],
          [10.09801, 56.3704062],
          [10.0977697, 56.3704157],
          [10.0975009, 56.3704573],
          [10.0971816, 56.3706009],
          [10.0969891, 56.3706929],
          [10.0968656, 56.3707054],
          [10.0965423, 56.3707221],
          [10.0964598, 56.3706929],
        ],
      },
      properties: {
        designator: null,
        type: 'Obstacle',
      },
      id: 'LSG-354',
    },
    {
      geometry: {
        type: 'Point',
        coordinates: [10.0998058, 56.3597815],
      },
      properties: {
        designator: "{'generator:output:electricity':",
        type: 'Obstacle',
      },
      id: 'PNT-1',
    },
    {
      geometry: {
        type: 'Point',
        coordinates: [10.0990505, 56.3617317],
      },
      properties: {
        designator: "{'generator:output:electricity':",
        type: 'Obstacle',
      },
      id: 'PNT-2',
    },
    {
      geometry: {
        type: 'Point',
        coordinates: [10.097531, 56.3656194],
      },
      properties: {
        designator: "{'generator:output:electricity':",
        type: 'Obstacle',
      },
      id: 'PNT-3',
    },
    {
      geometry: {
        type: 'Point',
        coordinates: [10.0982821, 56.3636702],
      },
      properties: {
        designator: "{'generator:output:electricity':",
        type: 'Obstacle',
      },
      id: 'PNT-4',
    },
    {
      geometry: {
        type: 'Point',
        coordinates: [10.0975298, 56.358755],
      },
      properties: {
        designator: "{'bus': 'yes', 'highway': 'bus_s",
        type: 'Obstacle',
      },
      id: 'PNT-5',
    },
  ],
};
