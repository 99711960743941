<app-card>
  <app-analytics-card-info>
    <div title>Page views</div>
    <div info>
      Number of page views in the period, broken down by page. Reloads count as a new page view. Time on page is how
      long a visitor stays on a page. For higher accuracy median values are used, and views under 5 seconds are
      excluded.
    </div>
    <div subtitle>{{ period() }}</div>
  </app-analytics-card-info>
  <app-analytics-bar-chart [data]="data()?.byPage" title="Views"></app-analytics-bar-chart>
  <app-analytics-bar-chart [data]="data()?.byTime" title="Time on page"></app-analytics-bar-chart>
  <div class="info">
    <i>
      * Time on page for Geo-Bird is not available here as Geo-Bird has multiple pages, making it incomparable to single
      pages. Please check
      <app-link href="https://analytics.google.com/analytics/web/#/p309911663">Google Analytics</app-link> for this
      information (seperate login required)
    </i>
  </div>
  <div class="info">
    More details in <app-link [href]="analyticsLink">Simple Analytics</app-link> (separate login required)
  </div>
</app-card>
