import { Component, InputSignal, signal } from '@angular/core';
import { ModalService } from '../../state/modal/modal.service';
import { GeneralFeedbackComponent } from './components/general-feedback/general-feedback.component';

@Component({
  selector: 'app-feedback-fab',
  standalone: true,
  imports: [],
  templateUrl: './feedback-fab.component.html',
  styleUrl: './feedback-fab.component.css',
})
export class FeedbackFabComponent {
  constructor(private modalService: ModalService) {}

  openFeedback() {
    const showCloseButton = signal<boolean>(true);
    this.modalService.openModal(GeneralFeedbackComponent, {
      data: { showCloseButton: showCloseButton as unknown as InputSignal<boolean> },
      animationType: 'slideInOut',
      trackOnClose: 'feedback_cancel',
    });
  }
}
