import { AnalyticsService } from '../../state/analytics/analytics.service';

const externalLinkTypes = ['geo-bird'];

function getHost(url: string) {
  const hostname = new URL(url).hostname;
  const domainParts = hostname.split('.');
  return domainParts.length > 1 ? domainParts[domainParts.length - 2] : hostname;
}

export function externalClick(e: MouseEvent, url: string, analytics: AnalyticsService) {
  if (e.target) {
    const hostname = getHost(url);
    if (externalLinkTypes.includes(hostname)) {
      analytics.trackEvent('external-link-' + hostname);
    }
  }
}
