import { Component, input, output } from '@angular/core';

type ButtonType = 'submit' | 'button';
type ButtonVariant = 'basic' | 'basic-primary' | 'basic-white' | 'primary' | 'outline-primary';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [],
  templateUrl: './button.component.html',
  styleUrl: './button.component.css',
})
export class ButtonComponent {
  type = input<ButtonType>('button');
  variant = input<ButtonVariant>('primary');
  disabled = input<boolean>(false);
  public buttonClick = output<MouseEvent>();

  handleClick(event: MouseEvent) {
    this.buttonClick.emit(event);
  }
}
