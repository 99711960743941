import { FeatureType } from '../interfaces/feedback';

const featureUrlMap: { [key in FeatureType]: string } = {
  'Public Landscape Objects': 'public-landscape-objects',
  'Slurry Optimization': 'slurry-optimization',
  'Soil Compaction': 'soil-compaction',
  Scheduling: 'scheduling',
  General: 'general',
  'feature not specified': 'feature-is-not-specified',
  OSM: 'osm',
};

const urlFeatureMap: { [key: string]: FeatureType } = {
  'public-landscape-objects': 'Public Landscape Objects',
  'slurry-optimization': 'Slurry Optimization',
  'soil-compaction': 'Soil Compaction',
  scheduling: 'Scheduling',
  general: 'General',
  osm: 'OSM',
  'feature-is-not-specified': 'feature not specified',
};

export function getFeatureUrl(feature: FeatureType): string {
  const url = featureUrlMap[feature];
  if (!url) {
    throw new Error(`Unknown feature: ${feature}`);
  }
  return url;
}

export function getFeatureName(url: string): FeatureType {
  const feature = urlFeatureMap[url];
  if (!feature) {
    throw new Error(`Unknown url: ${url}`);
  }
  return feature;
}
