import { Component, computed, input } from '@angular/core';
import { GraphComponent, lineGraphStyle } from '../../../../components/graph/graph.component';
import { TooltipItem } from 'chart.js';
import { CardComponent } from '../../../../components/card/card.component';

interface FeedbackGraphData {
  date: string;
  count: number;
}
@Component({
  selector: 'app-feedback-graph',
  standalone: true,
  imports: [GraphComponent, CardComponent],
  templateUrl: './feedback-graph.component.html',
  styleUrl: './feedback-graph.component.css',
})
export class FeedbackGraphComponent {
  public feedback = input.required<FeedbackGraphData[] | undefined>();
  public data = computed(() => {
    const feedback = this.feedback();
    if (!feedback) return;

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    const endDate = new Date();
    const filledData: FeedbackGraphData[] = [];

    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
      const formattedDate = date.toISOString().split('T')[0];
      const existing = feedback.find((f) => f.date === formattedDate);
      filledData.push({ date: formattedDate, count: existing ? existing.count : 0 });
    }

    return {
      datasets: [{ data: filledData.map((v) => v.count), label: 'Feedback given', ...lineGraphStyle, fill: 'none' }],
      labels: filledData.map((v) => v.date),
    };
  });
  public options = {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          title: (context: TooltipItem<'line'>[]) =>
            new Date(context[0].label).toLocaleString('da-DK', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            }),
        },
      },
    },
    scales: {
      x: {
        type: 'time' as const,
        time: {
          unit: 'day' as const,
          displayFormats: { day: 'DD.MM' },
        },
      },
    },
  };
}
