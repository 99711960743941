<app-card>
  <div class="header">
    <img src="icons/idea.svg" alt="feedback" />
    <h2>Feedback</h2>
  </div>

  <div class="content">
    @for (stat of feedbackStatistics | async; track stat) {
      <app-card [routerLink]="'feedback/' + (stat.feature | urlEncode)">
        <h3>{{ stat.feature }}</h3>
        <div class="box">
          @if (stat.rating >= 0) {
            <div class="wrapper">
              <span class="score">{{ stat.rating | number: "1.0-2" }}</span>
              <span class="label">score</span>
            </div>
          }
          <div class="wrapper">
            <span class="count">{{ stat.count }}</span>
            <span class="label">answers</span>
          </div>
        </div>
        <div class="link">see more</div>
      </app-card>
    }
  </div>
</app-card>
