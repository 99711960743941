<div>
  <h2>We'd love to hear your thoughts on this idea</h2>
  <p>
    Now that you have had a look, we would like to hear what you think. Your feedback is greatly appreciated, and will
    help us improve our work to develop valuable solutions for you. Thank you!
  </p>
  <p class="sub-text">How likely are you to use a solution like this if it were available?</p>
  <div class="rating">
    @for (rating of ratings; track rating; let i = $index) {
      <div class="element" (click)="openModal(i * 2.5)" (keypress)="openModal(i * 2.5)" tabindex="0">
        <img src="{{ rating.path }}" alt="{{ rating.label }}" />
        <div>{{ rating.label }}</div>
      </div>
    }
  </div>
</div>
