import { Component, OnInit } from '@angular/core';
import { TableLabels, TableRow, TableComponent } from '../../../components/table/table.component';
import { firstValueFrom } from 'rxjs';
import { BackendService } from '../../../api/bff/backend.service';
import { VisitorsGraphComponent } from './visitors-graph/visitors-graph.component';
import { WorldGraphComponent } from './world-graph/world-graph.component';
import { LinkComponent } from '../../../components/link/link.component';
import { environment } from '../../../../environments/environment';
import { CardComponent } from '../../../components/card/card.component';

@Component({
  selector: 'app-analytics',
  standalone: true,
  imports: [VisitorsGraphComponent, TableComponent, WorldGraphComponent, LinkComponent, CardComponent],
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.css',
})
export class AnalyticsComponent implements OnInit {
  protected visitorGraph?: { [date: string]: number };
  protected visitorTable?: { labels: TableLabels; data: TableRow[] };
  protected worldGraph?: { [country: string]: number };
  protected worldTable?: { labels: TableLabels; data: TableRow[] };
  protected totalVisitors = 0;
  protected analyticsLink = environment.analytics.link;

  constructor(private backend: BackendService) {}

  async ngOnInit() {
    const analytics = await firstValueFrom(this.backend.getAnalytics());
    this.visitorGraph = analytics.visitors.byDate;
    this.visitorTable = {
      labels: { date: 'Date', visitors: 'Visitors' },
      data: Object.entries(analytics.visitors.byDate).map(([date, visitors]) => ({ date, visitors })),
    };
    this.worldGraph = analytics.visitors.byCountry;
    this.worldTable = {
      labels: { country: 'Country', visitors: 'Visitors' },
      data: Object.entries(analytics.visitors.byCountry).map(([country, visitors]) => ({ country, visitors })),
    };
    this.totalVisitors = Object.values(analytics.visitors.byCountry).reduce((acc, visitors) => acc + visitors, 0);
  }
}
