import { Component } from '@angular/core';
import { InfoHoverComponent } from '../../../../components/info-hover/info-hover.component';

@Component({
  selector: 'app-analytics-card-info',
  standalone: true,
  imports: [InfoHoverComponent],
  templateUrl: './analytics-card-info.component.html',
  styleUrl: './analytics-card-info.component.css',
})
export class AnalyticsCardInfoComponent {}
