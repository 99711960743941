import { Component } from '@angular/core';
import { BackendService } from '../../../api/bff/backend.service';
import { AsyncPipe } from '@angular/common';
import { FeedbackGraphComponent } from './feedback-graph/feedback-graph.component';
import { FeedbackResponsesComponent } from './feedback-responses/feedback-responses.component';

@Component({
  selector: 'app-feedback',
  standalone: true,
  imports: [AsyncPipe, FeedbackGraphComponent, FeedbackResponsesComponent],
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.css',
})
export class FeedbackComponent {
  protected feedback = this.backend.getFeedbackOverview();

  constructor(private backend: BackendService) {}
}
