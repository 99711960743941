import { Component, input } from '@angular/core';
import { LinkComponent } from '../../../../components/link/link.component';
import { CardComponent } from '../../../../components/card/card.component';
import { environment } from '../../../../../environments/environment';
import { AnalyticsBarChartComponent } from './analytics-bar-chart/analytics-bar-chart.component';
import { AnalyticsCardInfoComponent } from '../analytics-card-info/analytics-card-info.component';
import { VisitorsData } from '@shared/interfaces/analytics';

@Component({
  selector: 'app-analytics-page-views',
  standalone: true,
  imports: [LinkComponent, CardComponent, AnalyticsBarChartComponent, AnalyticsCardInfoComponent],
  templateUrl: './analytics-page-views.component.html',
  styleUrl: './analytics-page-views.component.css',
})
export class AnalyticsPageViewsComponent {
  public period = input<string>();
  public data = input<VisitorsData>();
  protected analyticsLink = environment.analytics.link;
}
