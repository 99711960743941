@let data = formData();
<div class="wrapper">
  <h2>{{ data.title }}</h2>
  <p>{{ data.subtitle }}</p>
  <app-feedback-indicator [progress]="activePage" [total]="data.questions.length" />
  <form [formGroup]="form" (ngSubmit)="submitFeedback()">
    @for (question of data.questions[activePage]; track question.title) {
      @if (question.type === "radio") {
        <app-feedback-radio [question]="question" />
      } @else if (question.type === "radio-block") {
        <app-feedback-radio-block [question]="question" />
      } @else if (question.type === "textarea") {
        <app-feedback-text-area [question]="question" />
      } @else if (question.type === "check-block") {
        <app-feedback-check-block [question]="question" />
      } @else if (question.type === "pricing") {
        <app-feedback-pricing [question]="question" />
      } @else if (question.type === "consent") {
        <app-feedback-consent [question]="question" />
      } @else {
        <h2>Unknown question type: {{ question.type }}</h2>
      }
    }
    @if (activePage === data.questions.length) {
      <div class="success">
        <h2>thank you for your feedback</h2>
        <p>Your feedback has been recorded successfully</p>
      </div>
    }
    <div class="actions">
      @if (activePage !== data.questions.length) {
        <app-button
          (buttonClick)="updatePage(activePage - 1)"
          [variant]="activePage === 0 ? 'primary' : 'outline-primary'"
          [disabled]="activePage === 0 || form.invalid"
        >
          Previous
        </app-button>
      }
      @if (activePage !== data.questions.length - 1 && activePage !== data.questions.length) {
        <app-button (buttonClick)="updatePage(activePage + 1)" variant="primary" [disabled]="form.invalid">
          Next
        </app-button>
      }
      @if (activePage === data.questions.length - 1) {
        <app-button
          type="submit"
          (buttonClick)="updatePage(activePage + 1)"
          variant="primary"
          [disabled]="form.invalid"
        >
          Finish
        </app-button>
      }
      @if (activePage === data.questions.length) {
        <app-button (buttonClick)="close()" variant="primary"> Close </app-button>
      }
    </div>
  </form>
</div>
