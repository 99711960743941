import { Component, computed, input, Signal } from '@angular/core';
import { BarElement, ChartData, ChartDataset, ChartOptions, Plugin, Scale } from 'chart.js';
import { GraphComponent } from '../../../../../components/graph/graph.component';
import { PageUsage } from '@shared/interfaces/analytics';
@Component({
  selector: 'app-analytics-usage-chart',
  standalone: true,
  imports: [GraphComponent],
  templateUrl: './analytics-usage-chart.component.html',
  styleUrl: './analytics-usage-chart.component.css',
})
export class AnalyticsUsageChartComponent {
  public data = input<PageUsage[]>();
  public barChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {},
      y: {
        afterFit: (scale: Scale) => {
          scale.width = 50;
        },
      },
    },
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Clicks',
        align: 'start' as const,
      },
    },
  };

  protected graphData: Signal<ChartData<'bar'>> = computed(() => {
    const data = this.data();
    if (!data) return { labels: [], datasets: [] };
    const labels = data.map((d) => d.page);
    const datasets: ChartDataset<'bar'>[] = [];
    // Create an entry for event grouped by page
    // Grouping is done through the data array, filled with 0s except for the current event click-count
    data.forEach((page, pageIndex) => {
      page.events.forEach((event, eventIndex) => {
        const counts = new Array(labels.length).fill(0);
        counts[pageIndex] = event.count;
        datasets.push({
          data: counts,
          label: event.event,
          backgroundColor: this.getColor(pageIndex, eventIndex, page.events.length),
        });
      });
    });
    return { labels, datasets };
  });
  public barChartPlugins: Plugin<'bar'>[] = [
    {
      id: 'customBarSpacing',
      beforeDatasetsDraw(chart) {
        const xAxis = chart.scales['x'];
        const categoryWidth = (xAxis.right - xAxis.left) / chart.data.labels!.length;

        chart.data.labels!.forEach((_, categoryIndex) => {
          // Find all datasets with a non-zero value in this category
          const activeDatasets = chart.data.datasets
            .map((dataset, datasetIndex) => ({ datasetIndex, value: dataset.data[categoryIndex] }))
            .filter((entry) => entry.value !== 0);

          // Find number of bars in this category
          const barsInCategory = activeDatasets.length;
          if (barsInCategory === 0) return;

          // Caluculate bar width and offset
          const barWidth = (categoryWidth / barsInCategory) * 0.8;
          const offset = (categoryWidth - barsInCategory * barWidth) / 2;

          // Update bar positions to center them
          activeDatasets.forEach(({ datasetIndex }, position) => {
            const meta = chart.getDatasetMeta(datasetIndex);
            const bar = meta.data[categoryIndex] as BarElement & { width: number };
            if (!bar) return;

            bar.width = barWidth;
            bar.x = xAxis.left + categoryIndex * categoryWidth + offset + position * barWidth + barWidth / 2;
          });
        });
      },
    },
  ];

  private getColor(index: number, variationIndex = 1, totalVariations = 1): string {
    const hue = (index * 137.508) % 360; // Use golden angle approximation
    const lightness = 50 + ((variationIndex / totalVariations) * 20 - 10);
    return `hsla(${hue}, 70%, ${lightness}%, 0.65)`;
  }
}
