import { NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { profileViewOptions } from './options';
import { ButtonComponent } from '../../../components/button/button.component';
import { firstValueFrom, Subscription } from 'rxjs';
import { BackendService } from '../../../api/bff/backend.service';
import { AuthService } from '../../../state/auth/auth.service';
import { ModalService } from '../../../state/modal/modal.service';
import { User } from '@shared/interfaces/user';

@Component({
  selector: 'app-profile-view',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, NgFor, ButtonComponent],
  templateUrl: './profile-view.component.html',
  styleUrl: './profile-view.component.css',
})
export class ProfileViewComponent implements OnDestroy {
  protected form: FormGroup | undefined;
  protected options = profileViewOptions;
  private subscriptions: Subscription[] = [];
  public confirmModalClose: Promise<boolean>;
  private resolve!: (value: boolean) => void;

  constructor(
    private fb: FormBuilder,
    private backend: BackendService,
    private authService: AuthService,
    private modalService: ModalService,
  ) {
    this.confirmModalClose = new Promise<boolean>((resolve) => (this.resolve = resolve));

    this.authService.user.then((user) => {
      if (!user) return;
      this.form = this.fb.group({
        country: [user.country, Validators.required],
        yearsInIndustry: [user.yearsInIndustry, Validators.required],
        role: [user.role, Validators.required],
      });
      this.setupConditionalFields(user);

      this.subscriptions.push(
        this.form.get('role')?.valueChanges.subscribe((role) => {
          this.updateConditionalFields(role);
        }) as Subscription,
      );
      firstValueFrom(this.form!.valueChanges).then(() => this.resolve(true));
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async updateProfile() {
    if (this.form?.valid) {
      const user = await this.authService.user;
      const updatedUser = await firstValueFrom(this.backend.putUser({ ...user, ...this.form.value }));
      this.authService.setUser(updatedUser);
      this.modalService.closeModal();
    }
  }

  private updateConditionalFields(role: string) {
    if (!this.form) return;
    this.form.removeControl('sizeUnit');
    this.form.removeControl('farmSize');
    this.form.removeControl('companySize');
    this.form.removeControl('companyName');
    this.form.removeControl('livestock');

    if (role === 'Farm owner' || role === 'Farm worker') {
      this.form.addControl('sizeUnit', this.fb.control('Hectares', Validators.required));
      this.form.addControl('farmSize', this.fb.control('', Validators.required));
      this.form.addControl('livestock', this.fb.control('', Validators.required));
    } else if (role === 'Contractor, owner' || role === 'Contractor, worker') {
      this.form.addControl('companySize', this.fb.control('', Validators.required));
    } else if (role === 'Dealer' || role === 'Agronomist, advisor or consultant') {
      this.form.addControl('companyName', this.fb.control('', Validators.required));
    }
  }

  private setupConditionalFields({ role, sizeUnit, farmSize, livestock, companyName, companySize }: User) {
    if (!this.form) return;
    if (role === 'Farm owner' || role === 'Farm worker') {
      this.form.addControl('sizeUnit', this.fb.control(sizeUnit, Validators.required));
      this.form.addControl('farmSize', this.fb.control(farmSize, Validators.required));
      this.form.addControl('livestock', this.fb.control(livestock, Validators.required));
    } else if (role === 'Contractor, owner' || role === 'Contractor, worker') {
      this.form.addControl('companySize', this.fb.control(companySize, Validators.required));
    } else if (role === 'Dealer' || role === 'Agronomist, advisor or consultant') {
      this.form.addControl('companyName', this.fb.control(companyName, Validators.required));
    }
  }
}
