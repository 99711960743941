import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { marked } from 'marked';
import { ButtonComponent } from '../../../components/button/button.component';
import { AuthService } from '../../../state/auth/auth.service';
import { ModalService } from '../../../state/modal/modal.service';
import { BackendService } from '../../../api/bff/backend.service';
import { eulaVersion } from '@shared/utils/eulaVersion';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-eula-view',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './eula-view.component.html',
  styleUrl: './eula-view.component.css',
})
export class EulaViewComponent implements OnInit {
  public signupFlow = true;
  protected eula = '';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private modalService: ModalService,
    private backend: BackendService,
  ) {}

  ngOnInit(): void {
    this.loadEulaText();
  }

  loadEulaText(): void {
    this.http
      .get('eula/v2(2025-02-11_AGCO_SOLUTIONS_EULA).md', { responseType: 'text' })
      .subscribe((data) => (this.eula = marked(data) as string));
  }

  async acceptEula() {
    if (this.signupFlow) {
      this.authService.signUp();
    } else {
      const user = await this.authService.user;
      if (!user) throw new Error('User not found');
      await firstValueFrom(this.backend.putUser({ ...user, eulaVersion }));
      this.modalService.closeModal();
    }
  }
}
