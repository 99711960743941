export const stepData = [
  {
    imgSrc: 'images/SCH_step1.png',
    altText: 'Step 1',
    heading: 'John considers which is the most efficient order of his field',
    paragraph:
      'This year he is including two new fields in his production, and now he considers what is the most efficient order to operate his fields.',
  },
  {
    imgSrc: 'images/SCH_step2.png',
    altText: 'Step 2',
    heading: '2. John plans the field operation',
    paragraph:
      'He selects all fields for the operation and the equipment he wants to use for the operation. Then he selects a start and ending point.',
  },
  {
    imgSrc: 'images/SCH_step3.png',
    altText: 'Step 3',
    heading: '3. John inspects the suggested schedule',
    paragraph:
      'The tool suggests a schedule with the most efficient order to operate the fields, considering the weather forecast and minimizing transport and idle time.',
  },
  {
    imgSrc: 'images/SCH_step4.png',
    altText: 'Step 4',
    heading: '4. John is ready to do the field operation',
    paragraph:
      'He follows the suggested schedule to minimize transport and idle time. He uses the tool for rescheduling, e.g. if the weather changes.',
  },
];
