import { Component, OnInit } from '@angular/core';
import { MapComponent } from '../../components/map/map.component';
import { CardComponent } from '../../components/card/card.component';
import { BulletComponent } from '../../components/bullet/bullet.component';
import { RibbonComponent } from '../../components/ribbon/ribbon.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FileUploadComponent } from '../../components/file-upload/file-upload.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { PortalService } from '../../api/portal/portal.service';
import { firstValueFrom } from 'rxjs';
import { OSMResponse } from '../../api/portal/portal.service.interface';
import { IconComponent } from '../../components/icon/icon.component';
import { NgStyle } from '@angular/common';
import { CardTitleComponent } from '../../components/card-title/card-title.component';
import { FeedbackCardComponent } from '../../components/feedback-card/feedback-card.component';
import { LinkComponent } from '../../components/link/link.component';
import { downloadFile } from '../../utils/download/download';

@Component({
  selector: 'app-osm-page',
  standalone: true,
  imports: [
    MapComponent,
    CardComponent,
    BulletComponent,
    RibbonComponent,
    ButtonComponent,
    FileUploadComponent,
    ReactiveFormsModule,
    IconComponent,
    NgStyle,
    CardTitleComponent,
    FeedbackCardComponent,
    LinkComponent,
  ],
  templateUrl: './osm-page.component.html',
  styleUrl: './osm-page.component.css',
})
export class OSMPageComponent implements OnInit {
  protected fileForm!: FormGroup;
  protected mapFeatures: OSMResponse | null | 'loading' | 'no-data' = null;
  protected mapHeight = '800px';

  constructor(
    private portal: PortalService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.fileForm = this.fb.group({
      file: [null, Validators.required],
    });
  }

  async uploadFile() {
    this.mapFeatures = 'loading';
    const file: File = this.fileForm.value.file;
    const res = await firstValueFrom(this.portal.uploadOSMFile(file));
    this.mapFeatures = !res.features || res.features.length === 0 ? 'no-data' : res;
  }

  async download() {
    const file: File = this.fileForm.value.file;
    const blob = await firstValueFrom(this.portal.downloadOSMFile(file));
    downloadFile(blob, 'osm.zip');
  }

  goToFeedback() {
    const location = window.location.href;
    return location.includes('#') ? location : location + '#feedback';
  }
}
