<app-card class="page-usage">
  <app-analytics-card-info>
    <div title>Page usage</div>
    <div info>
      Page usage grouped by page. This includes data on user interactions, such as button clicks, and shows the total
      number of interactions for each event type.
    </div>
    <div subtitle>{{ period() }}</div>
  </app-analytics-card-info>
  <app-analytics-usage-chart [data]="pageUsage()" />
  <div class="info">
    More details in <app-link [href]="analyticsLink">Simple Analytics</app-link> (separate login required)
  </div>
</app-card>
