<div class="wrapper">
  <nav>
    <ul>
      <li>
        <app-button variant="basic" (buttonClick)="showProfile()">
          <span><img src="icons/profile.svg" alt="profile" />User profile</span>
        </app-button>
      </li>
      <li>
        <app-button variant="basic">
          <app-link variant="basic" href="https://www.agcocorp.com/privacy">
            <span><img src="icons/policy.svg" alt="policy" />Privacy policy</span>
          </app-link>
        </app-button>
      </li>
      <li>
        <app-button variant="basic" (buttonClick)="showCookies()">
          <span><img src="icons/cookie.svg" alt="cookie" />Cookie preferences</span>
        </app-button>
      </li>
      <li>
        <app-button variant="basic" (buttonClick)="logout()">
          <span><img src="icons/logout.svg" alt="logout" />Log out</span>
        </app-button>
      </li>
    </ul>
  </nav>
  <img class="logo" src="agco-logo.png" alt="logo" />
</div>
