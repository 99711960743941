import { Component } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { LinkComponent } from '../link/link.component';
import { filter, map, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-breadcrumbs',
  standalone: true,
  imports: [IconComponent, RouterLink, LinkComponent, AsyncPipe],
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.css',
})
export class BreadcrumbsComponent {
  protected path: Observable<string[]>;

  constructor(private router: Router) {
    this.path = router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => event.url.split('#')[0].split('/').slice(1)),
    );
  }
}
