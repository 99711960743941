import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function maxArrayLength(max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (Array.isArray(control.value) && control.value.length > max) {
      return { maxArrayLength: { maxLength: max, actualLength: control.value.length } };
    }
    return null;
  };
}
