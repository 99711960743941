import { Component, input } from '@angular/core';
import { ChartData, ChartOptions, ChartType, Plugin } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-graph',
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: './graph.component.html',
  styleUrl: './graph.component.css',
})
export class GraphComponent {
  public data = input.required<ChartData<ChartType> | undefined>();
  public type = input.required<ChartType>();
  public plugins = input<Plugin[]>();
  public options = input<ChartOptions<ChartType>>();
}

export const lineGraphStyle = {
  backgroundColor: 'rgba(50,151,179,0.2)',
  borderColor: 'rgb(50,151,179)',
  pointBackgroundColor: 'rgb(50,151,179)',
  pointBorderColor: '#fff',
  pointHoverBackgroundColor: '#fff',
  pointHoverBorderColor: 'rgba(50,151,179,0.8)',
  fill: 'origin',
};
