import { Component, effect, input } from '@angular/core';
import { CardComponent } from '../../../../components/card/card.component';
import { FeedbackStatistic } from '@shared/interfaces/feedback';
import { RouterModule } from '@angular/router';
import { ResponseCardData, ResponseCardComponent } from './response-card/response-card.component';
import { getFeatureUrl } from '@shared/utils/featureMapper';

@Component({
  selector: 'app-feedback-responses',
  standalone: true,
  imports: [CardComponent, RouterModule, ResponseCardComponent],
  templateUrl: './feedback-responses.component.html',
  styleUrl: './feedback-responses.component.css',
})
export class FeedbackResponsesComponent {
  public feedback = input<FeedbackStatistic[]>();
  protected cards: ResponseCardData[] = [];

  constructor() {
    effect(() => {
      this.cards = (this.feedback() || []).map((feedback) => {
        const isGeneral = feedback.feature === 'General';
        return {
          title: feedback.feature,
          subtitle: isGeneral
            ? 'Received via homepage dialogue or the Feedback button on every page.'
            : 'Survey responses',
          count: feedback.count,
          comments: isGeneral ? 'Comments' : 'Total responses',
          recent: `(+${feedback.recentCount} last 7 days)`,
          link: getFeatureUrl(feedback.feature),
          linkLabel: isGeneral ? 'See comments' : 'See responses',
          score: feedback.rating,
        };
      });
    });
  }
}
