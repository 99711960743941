import { Component } from '@angular/core';
import { AnalyticsComponent } from './analytics/analytics.component';
import { FeedbackStatisticsComponent } from './feedback-statistics/feedback-statistics.component';

@Component({
  selector: 'app-admin-page',
  standalone: true,
  imports: [AnalyticsComponent, FeedbackStatisticsComponent],
  templateUrl: './admin-page.component.html',
  styleUrl: './admin-page.component.css',
})
export class AdminPageComponent {}
