export function interpolateColor(startHex: string, endHex: string, factor: number): string {
  const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  };

  const rgbToHex = (r: number, g: number, b: number) => {
    return (
      '#' +
      [r, g, b]
        .map((x) => {
          const hex = x.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        })
        .join('')
    );
  };

  const startRgb = hexToRgb(startHex);
  const endRgb = hexToRgb(endHex);

  const r = Math.round(startRgb.r + factor * (endRgb.r - startRgb.r));
  const g = Math.round(startRgb.g + factor * (endRgb.g - startRgb.g));
  const b = Math.round(startRgb.b + factor * (endRgb.b - startRgb.b));

  return rgbToHex(r, g, b).toUpperCase();
}
