@let q = question();
<div [formGroup]="form">
  <app-feedback-title [title]="q.title" [subtitle]="q.subtitle" />
  <div class="currency-form">
    My prices are in
    <div class="currencies">
      <div class="currency">
        <input type="radio" [formControlName]="q.title" (change)="currencyChange($event)" value="eur" id="eur" />
        <label for="eur">Euro (€)</label>
      </div>
      <div class="currency">
        <input type="radio" [formControlName]="q.title" (change)="currencyChange($event)" value="usd" id="usd" />
        <label for="usd">US dollars ($)</label>
      </div>
    </div>
  </div>
  <div class="options">
    @for (option of q.options; track option) {
      <div class="option">
        <label for="{{ option }}">
          <span>{{ option }}</span>
          <span>{{ currency }}</span>
        </label>
        <div class="input-wrapper">
          <input type="text" [formControlName]="option" />
          @if (form.get(option)?.invalid && form.get(option)?.touched) {
            <div class="error">Only numbers allowed</div>
          }
        </div>
      </div>
    }
  </div>
</div>
