@let cols = columns();
<table>
  <thead>
    <tr>
      @for (column of cols; track column) {
        <th>{{ labels()[column] }}</th>
      }
    </tr>
  </thead>
  <tbody>
    @for (row of data(); track row) {
      <tr>
        @for (column of cols; track column) {
          <td>{{ row[column] }}</td>
        }
      </tr>
    }
  </tbody>
</table>
