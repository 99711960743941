@if (action(); as action) {
  @if (isExternalLink(action.href)) {
    <a class="body" [href]="action.href" target="_blank" (click)="externalClick($event)">
      <ng-container [ngTemplateOutlet]="body"></ng-container>
    </a>
  } @else {
    <div class="body" [routerLink]="action.href"><ng-container [ngTemplateOutlet]="body"></ng-container></div>
  }
} @else {
  <ng-container [ngTemplateOutlet]="body"></ng-container>
}

<ng-template #body>
  <app-card>
    <div>
      @if (ribbon(); as ribbon) {
        <app-ribbon [type]="ribbon" />
      }
    </div>
    <div class="card-header">
      <img [src]="icon()" alt="Icon" />
      <ng-content select="[card-title]"></ng-content>
    </div>
    <div class="card-body">
      @if (img()) {
        <div class="image-wrapper"><img [src]="img()" alt="Image" /></div>
      }
      <ng-content select="[card-body]"></ng-content>
    </div>
    <div class="card-actions">{{ action()?.label }}</div>
  </app-card>
</ng-template>
