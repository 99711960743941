import { Component, computed, input, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { GeoData } from '../../../../types/GeoData';
import { HttpClient } from '@angular/common/http';
import { topojson } from 'chartjs-chart-geo';
import { GraphComponent } from '../../../../components/graph/graph.component';
import { ChartData, ChartOptions } from 'chart.js';
import { interpolateColor } from '../../../../utils/color/color';

@Component({
  selector: 'app-world-graph',
  standalone: true,
  imports: [GraphComponent],
  templateUrl: './world-graph.component.html',
  styleUrl: './world-graph.component.css',
})
export class WorldGraphComponent {
  public visitors = input<{ [date: string]: number } | undefined>();
  private world: Signal<GeoData | undefined> = toSignal(
    this.httpClient.get<GeoData>('world-atlas/countries-110m.json'),
  );
  public data: Signal<ChartData | undefined> = computed(() => {
    const world = this.world();
    const visitors = this.visitors();
    if (!world || !visitors) return;
    const countries = topojson.feature(world, world.objects.countries).features;
    const land = topojson.feature(world, world.objects.land).features;
    return {
      labels: countries.map((d) => d.properties.name),
      datasets: [
        {
          label: 'Countries',
          outline: land,
          data: countries.map((d) => ({
            feature: d,
            value: visitors[d.properties.name] || 0,
          })),
        },
      ],
    };
  });
  public options: ChartOptions = {
    maintainAspectRatio: false,
    scales: {
      projection: { axis: 'x', projection: 'naturalEarth1' },
      color: {
        axis: 'x',
        quantize: 25,
        interpolate: (v) => interpolateColor('#ffffff', '#004e6e', v),
        legend: {
          position: 'left',
          length: 380,
          margin: { left: 20 },
        },
      },
    },
  };

  constructor(private httpClient: HttpClient) {}
}
