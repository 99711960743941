import { Component, input, InputSignal, signal } from '@angular/core';
import { ModalService } from '../../state/modal/modal.service';
import { FeedbackFormComponent } from '../feedback-form/feedback-form.component';
import { slurryFormData } from '../../pages/slurry-optimization-page/feedback';
import { osmFormData } from '../../pages/osm-page/feedback';
import { FeedbackFormData } from '../feedback-form/feedback-types';
import { FeatureType } from '@shared/interfaces/feedback';
import { soilCompactionFormData } from '../../pages/soil-compaction-page/feedback';
import { schedulingFormData } from '../../pages/scheduling-page/feedback';

const dummy = { title: 'dummy', subtitle: 'dummy', questions: [] };
const formDataMap: { [key in FeatureType]: FeedbackFormData } = {
  General: dummy,
  'Slurry Optimization': slurryFormData,
  'Soil Compaction': soilCompactionFormData,
  'Public Landscape Objects': osmFormData,
  Scheduling: schedulingFormData,
  OSM: dummy,
  'feature not specified': dummy,
};

@Component({
  selector: 'app-feedback-card',
  standalone: true,
  imports: [],
  templateUrl: './feedback-card.component.html',
  styleUrl: './feedback-card.component.css',
})
export class FeedbackCardComponent {
  public feature = input.required<FeatureType>();
  protected ratings = [
    { path: '/rating/VeryUnlikely.png', label: 'Very unlikely' },
    { path: '/rating/SomewhatUnlikely.png', label: 'Somewhat unlikely' },
    { path: '/rating/Neutral.png', label: 'Neutral' },
    { path: '/rating/SomewhatLikely.png', label: 'Somewhat likely' },
    { path: '/rating/VeryLikely.png', label: 'Very likely' },
  ];

  constructor(private modalService: ModalService) {}

  protected openModal(rating: number) {
    const data = formDataMap[this.feature()];
    const formDataSignal = signal<FeedbackFormData>(data);
    const ratingSignal = signal<number>(rating);
    this.modalService.openModal(FeedbackFormComponent, {
      data: {
        rating: ratingSignal as unknown as InputSignal<number>,
        formData: formDataSignal as unknown as InputSignal<FeedbackFormData>,
        feature: this.feature,
      },
      confirmModalClose: true,
    });
  }
}
