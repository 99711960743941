import { Component, input, OnInit } from '@angular/core';
import { FeedbackTitleComponent } from '../feedback-title/feedback-title.component';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionItem } from '../../feedback-types';

@Component({
  selector: 'app-feedback-text-area',
  standalone: true,
  imports: [FeedbackTitleComponent, ReactiveFormsModule],
  templateUrl: './feedback-text-area.component.html',
  styleUrl: './feedback-text-area.component.css',
})
export class FeedbackTextAreaComponent implements OnInit {
  public question = input.required<QuestionItem>();
  protected form!: FormGroup;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }
}
