import { Component } from '@angular/core';

@Component({
  selector: 'app-field-view-page',
  standalone: true,
  imports: [],
  templateUrl: './field-view-page.component.html',
  styleUrl: './field-view-page.component.css',
})
export class FieldViewPageComponent {}
