<app-card>
  <app-analytics-card-info>
    <div title>New Users</div>
    <div info>
      Users created for InnovationHub or Geo-Bird in the given period. Each user account can be used for both platforms,
      but is only counted once here.
    </div>
    <div subtitle>{{ period() }}</div>
  </app-analytics-card-info>
  <div class="content">Users created in the period</div>
  <div class="result">{{ newUsers() }}</div>
</app-card>
<app-card>
  <app-analytics-card-info>
    <div title>Users</div>
    <div info>
      Total number of users created for InnovationHub or Geo-Bird since the launch of Geo-Bird. Each user account can be
      used for both platforms, but is only counted once here.
    </div>
    <div subtitle>In total</div>
  </app-analytics-card-info>
  <div class="content">Total users</div>
  <div class="result">{{ totalUsers() }}</div>
</app-card>
