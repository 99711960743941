import { RibbonType } from '../../components/ribbon/ribbon.component';

interface FeatureCard {
  icon?: string;
  title: string;
  content: string;
  action: {
    href: string;
    label: string;
  };
  image: string;
  ribbon?: RibbonType;
}

export const featureCards: FeatureCard[] = [
  {
    icon: 'icons/geo-bird.svg',
    title: 'Geo-Bird - Wayline management',
    image: 'images/home_geo-bird.png',
    content: 'Create optimized waylines for your chosen fields and implements in just a few simple steps.',
    action: {
      href: '/geo-bird',
      label: 'Go to Geo-Bird',
    },
    ribbon: 'product',
  },
  {
    title: 'Public landscape objects',
    content:
      'Find public information about objects in your fields, such as windmills, lakes, roads, etc. Download an ISOXML file with the objects and use it in your tractor terminal or farm management system.',
    image: 'images/home_OSM.png',
    action: {
      href: '/public-landscape-objects',
      label: 'Go to Public landscape objects',
    },
    ribbon: 'prototype',
  },
  {
    title: 'Soil Compaction',
    content:
      'Minimize soil compaction by optimizing how you drive in the field, which machinery you use and how you configure your tires.',
    image: 'images/home_soilcompaction.png',
    action: {
      href: '/soil-compaction',
      label: 'Go to Soil compaction',
    },
    ribbon: 'idea',
  },
  {
    title: 'Slurry optimization',
    content:
      'Optimize the route in the field and adjust application rate for slurry application to minimize unnecessary driving in the field.',
    image: 'images/home_slurry.png',
    action: {
      href: '/slurry-optimization',
      label: 'Go to Slurry optimization',
    },
    ribbon: 'idea',
  },
  {
    title: 'Scheduling',
    content: 'Easy creation of an efficient plan for your field operations.',
    image: 'images/home_scheduling.png',
    action: {
      href: '/scheduling',
      label: 'Go to Scheduling',
    },
    ribbon: 'idea',
  },
];
