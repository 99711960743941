import { Component, input } from '@angular/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-graph',
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: './graph.component.html',
  styleUrl: './graph.component.css',
})
export class GraphComponent {
  public data = input.required<ChartData<ChartType> | undefined>();
  public type = input.required<ChartType>();
  public options = input<ChartOptions<ChartType>>();
}
