import { Component } from '@angular/core';
import { LinkComponent } from '../../components/link/link.component';

@Component({
  selector: 'app-not-found-page',
  standalone: true,
  imports: [LinkComponent],
  templateUrl: './not-found-page.component.html',
  styleUrl: './not-found-page.component.css',
})
export class NotFoundPageComponent {}
