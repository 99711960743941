import { Component, input, output } from '@angular/core';

type ButtonType = 'submit' | 'button';
type ButtonSize = 'medium' | 'large';
type ButtonVariant =
  | 'basic'
  | 'basic-primary'
  | 'basic-white'
  | 'primary'
  | 'outline-primary'
  | 'tertiary'
  | 'tertiary-white'
  | 'tertiary-inverted';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [],
  templateUrl: './button.component.html',
  styleUrl: './button.component.css',
})
export class ButtonComponent {
  public type = input<ButtonType>('button');
  public variant = input<ButtonVariant>('primary');
  public disabled = input<boolean>(false);
  public size = input<ButtonSize>('medium');
  public buttonClick = output<MouseEvent>();

  handleClick(event: MouseEvent) {
    this.buttonClick.emit(event);
  }
}
