<app-card class="header">
  <div>
    <app-ribbon type="idea" />
    <div class="title"><img src="icons/drop.svg" alt="Icon" /> SLURRY OPTIMIZATION</div>
    <p>Imagine you could avoid running empty in the middle of a track</p>
    <p>
      Our idea is to make a simple tool that will help minimise the number of split tracks when applying slurry. This
      tool will inform you about which tracks to operate for each load and how to adjust the application rate. All you
      have to do is provide the size of your slurry tanker, the target application rate and the maximum application rate
      variation that you will allow in your field.
    </p>
  </div>
  <div class="try">
    <div>TRY IT OUT</div>
    <p>Adjust the parameters and investigate how the maps are updated according to your selections.</p>
    <p>Compare the tank loads, number of split tracks etc. for the application maps with and without optimization.</p>
    <p class="info-feedback">
      ... and let us know what you think -
      <app-link target="_self" [href]="goToFeedback()">send your feedback</app-link>
    </p>
  </div>
</app-card>
<app-card class="form">
  <app-card-title title="Parameters" subtitle="Change the parameters and see the optimization below" />
  <form [formGroup]="slurryForm" (ngSubmit)="calculateOptimizations()">
    @for (fieldSet of formData; track fieldSet) {
      <fieldset>
        <legend class="title">{{ fieldSet.legend }}</legend>
        <div class="options-wrapper">
          @for (option of fieldSet.options; track option) {
            <input type="radio" [value]="option.value" [formControlName]="fieldSet.formControl" [id]="option.label" />
            <label [for]="option.label">{{ option.label }}</label>
          }
        </div>
      </fieldset>
    }
    <app-button type="submit" variant="primary" [disabled]="!isPending">Show results</app-button>
  </form>
</app-card>
<div class="previews">
  <app-card data-cy="preview">
    <app-card-title title="Slurry application without optimization" subtitle="No optimization, application rate 100%" />
    <app-slurry-preview [url]="baseURL" />
  </app-card>
  <app-card>
    <app-card-title
      title="Slurry application with optimization"
      subtitle="Optimized, application rate adjusted according to settings"
    />
    <app-slurry-preview [url]="optimizedURL" />
  </app-card>
</div>
<div>
  <app-card id="feedback">
    <app-feedback-card feature="Slurry Optimization" />
  </app-card>
</div>
