import { Component } from '@angular/core';
import { CardComponent } from '../../components/card/card.component';
import { RibbonComponent } from '../../components/ribbon/ribbon.component';
import { LinkComponent } from '../../components/link/link.component';
import { CardTitleComponent } from '../../components/card-title/card-title.component';
import { metricsData, stepData } from './content';
import { MetricsComponent } from '../../components/metrics/metrics.component';
import { FeedbackCardComponent } from '../../components/feedback-card/feedback-card.component';
import { StepsComponent } from '../../components/steps/steps.component';

@Component({
  selector: 'app-solgras-page',
  standalone: true,
  imports: [
    CardComponent,
    RibbonComponent,
    LinkComponent,
    CardTitleComponent,
    MetricsComponent,
    FeedbackCardComponent,
    StepsComponent,
  ],
  templateUrl: './solgras-page.component.html',
  styleUrl: './solgras-page.component.css',
})
export class SolgrasPageComponent {
  protected steps = stepData;
  protected metrics = metricsData;

  goToFeedback() {
    const location = window.location.href;
    return location.includes('#') ? location : location + '#feedback';
  }
}
