<app-header [isOnLandingPage]="(isLandingPage$ | async) ?? false" />
<div class="content">
  <app-breadcrumbs />
  <router-outlet />
</div>
@if (isLandingPage$ | async) {
  <app-footer />
} @else {
  <app-feedback-fab />
}
