<header #header>
  @if (!isOnLandingPage()) {
    <app-link variant="white" routerLink="/home" class="icon">
      <img src="icons/home.svg" alt="home" />
    </app-link>
  } @else {
    <div></div>
  }
  <app-link variant="white" routerLink="/home">
    <div class="title">
      <img src="icons/logo.svg" alt="logo" />
      <h1>InnovationHub</h1>
    </div>
  </app-link>
  <nav>
    @if (user?.isAdmin) {
      <app-link variant="white" routerLink="/admin">
        <img src="icons/administration.svg" alt="administration" class="icon" />
      </app-link>
    }
    @if (user) {
      <app-button variant="basic" (buttonClick)="toggleMenu()">
        <img src="icons/profile2.svg" alt="profile" class="icon" />
      </app-button>
    } @else {
      <app-button variant="basic-white" (buttonClick)="doLogin()">Login</app-button>
    }
    @if (isOnLandingPage()) {
      <app-button variant="tertiary" (buttonClick)="doSignUp()">Get started</app-button>
    }
  </nav>
  @if (menuOpen) {
    <app-header-menu />
  }
</header>
