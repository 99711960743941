import { Component } from '@angular/core';
import { CardComponent } from '../../components/card/card.component';
import { RibbonComponent } from '../../components/ribbon/ribbon.component';
import { LinkComponent } from '../../components/link/link.component';
import { CardTitleComponent } from '../../components/card-title/card-title.component';
import { metricsData, stepData } from './content';
import { MetricsComponent } from '../../components/metrics/metrics.component';
import { FeedbackCardComponent } from '../../components/feedback-card/feedback-card.component';
import { StepsComponent } from '../../components/steps/steps.component';
import { AnalyticsService } from '../../state/analytics/analytics.service';

@Component({
  selector: 'app-soil-compaction-page',
  standalone: true,
  imports: [
    CardComponent,
    RibbonComponent,
    LinkComponent,
    CardTitleComponent,
    MetricsComponent,
    FeedbackCardComponent,
    StepsComponent,
  ],
  templateUrl: './soil-compaction-page.component.html',
  styleUrl: './soil-compaction-page.component.css',
})
export class SoilCompactionPageComponent {
  protected steps = stepData;
  protected metrics = metricsData;
  private onPlayCheck: { [key: string]: boolean } = {};

  constructor(private analytics: AnalyticsService) {}

  goToFeedback() {
    const location = window.location.href;
    return location.includes('#') ? location : location + '#feedback';
  }

  onPlay(video: number) {
    if (this.onPlayCheck[video]) return;
    this.onPlayCheck[video] = true;
    this.analytics.trackEvent('Soil Compaction', 'Video Suggestion ' + video);
  }
}
