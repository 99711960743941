export const environment = {
  configuration: 'development',
  production: false,
  backend: 'https://api.innovationhub.dev.agco-opsmgmt.com',
  portal: {
    osm: 'https://demo.v1.o2isvc.dev.agco-opsmgmt.com/osm2isoxml',
  },
  analytics: {
    link: 'https://dashboard.simpleanalytics.com/innovationhub.dev.agco-opsmgmt.com',
    script: 'https://info.agco-innovationhub.com/latest.dev.js',
    hostName: 'innovationhub.dev.agco-opsmgmt.com',
  },
  Auth: {
    domain: 'operational-planning.auth.eu-central-1.amazoncognito.com',
    userPoolId: 'eu-central-1_L0XzyMqKo',
    userPoolClientId: '3qrp0sqhtoi35o47l3mh5p7j14',
    redirectSignIn: 'https://innovationhub.dev.agco-opsmgmt.com',
    redirectSignOut: 'https://innovationhub.dev.agco-opsmgmt.com',
    test: {
      email: 'agcoinnovationhub-testuser@mailinator.com',
      password: '4^C$AjkzM6CmL$K@tfH',
    },
  },
};
