import { FeedbackContext } from '@shared/interfaces/feedback';

export function getFeedbackContext(): FeedbackContext {
  const url = window.location.pathname;
  switch (url) {
    case '/public-landscape-objects':
      return 'Public Landscape Objects';
    case '/slurry-optimization':
      return 'Slurry Optimization';
    case '/soil-compaction':
      return 'Soil Compaction';
    case '/home':
      return 'Home';
    default:
      return 'Other';
  }
}
