import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FeedbackFabComponent } from '../components/feedback-fab/feedback-fab.component';
import { BreadcrumbsComponent } from '../components/breadcrumbs/breadcrumbs.component';
import { ModalComponent } from '../components/modal/modal.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterModule, HeaderComponent, FeedbackFabComponent, FooterComponent, BreadcrumbsComponent, ModalComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css',
})
export class LayoutComponent {}
