import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OSMResponse, PortalServiceInterface } from './portal.service.interface';
import { PortalServiceMock } from './portal.service.mock';

@Injectable({
  providedIn: 'root',
})
export class PortalService implements PortalServiceInterface {
  // TODO: Use real backend when auth is working
  constructor(
    private http: HttpClient,
    private mock: PortalServiceMock,
  ) {}

  uploadOSMFile(file: File): Observable<OSMResponse> {
    const formData = new FormData();
    formData.append('file', file);
    // const res$ = this.http.post<OSMResponse>(environment.portal.osm, formData);
    const res$ = this.mock.uploadOSMFile(file);
    return res$;
  }

  downloadOSMFile(): Observable<void> {
    console.log('download not implemented yet.');
    return this.mock.downloadOSMFile();
  }
}
