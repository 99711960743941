import { FormControl } from '@angular/forms';

export class CheckboxArrayControl extends FormControl {
  constructor() {
    super([]);
  }

  addOption(option: string): void {
    const currentValue = this.value as string[];
    if (!currentValue.includes(option)) {
      this.setValue([...currentValue, option]);
    }
  }

  removeOption(option: string): void {
    const currentValue = this.value as string[];
    this.setValue(currentValue.filter((item) => item !== option));
  }

  toggleOption(option: string): void {
    const currentValue = this.value as string[];
    if (currentValue.includes(option)) {
      this.removeOption(option);
    } else {
      this.addOption(option);
    }
  }
}
