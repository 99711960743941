@let f = feedbackPromise | async;
@if (settings && f) {
  <app-card>
    <div class="header">
      <img src="icons/idea.svg" alt="feedback" />
      <h2>{{ f[0].feature }}</h2>
    </div>
    <p>
      Answers: <b>{{ f.length }}</b>
      @if (settings.columns["rating"]) {
        <span
          >, Score: <b>{{ rating | number: "1.0-2" }}</b></span
        >
      }
    </p>
    <angular2-smart-table class="table" [settings]="settings" [source]="data"></angular2-smart-table>
  </app-card>
}
