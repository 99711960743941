import { Component, input } from '@angular/core';

export interface MetricsData {
  label: string;
  value: string;
  indicator?: 'warning' | 'error';
}

@Component({
  selector: 'app-metrics',
  standalone: true,
  imports: [],
  templateUrl: './metrics.component.html',
  styleUrl: './metrics.component.css',
})
export class MetricsComponent {
  public metrics = input<MetricsData[]>([]);
}
