<app-card>
  <div class="header">
    <img src="icons/profile.svg" alt="analytics" />
    <h2>Analytics</h2>
  </div>
  <div class="analytics">
    <div class="visitors">
      <app-visitors-graph [visitors]="visitorGraph"></app-visitors-graph>
      <app-table [data]="visitorTable?.data" [labels]="visitorTable?.labels"></app-table>
    </div>
    <div class="countries">
      <app-world-graph [visitors]="worldGraph"></app-world-graph>
      <app-table [data]="worldTable?.data" [labels]="worldTable?.labels"></app-table>
      <div class="info-box">
        <h3>Total visitors the past 30 days:</h3>
        <p class="number">{{ totalVisitors }}</p>
        <p>
          See more information
          <app-link [href]="analyticsLink">here</app-link>
        </p>
      </div>
    </div>
  </div>
</app-card>
