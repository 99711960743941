import { Component, input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { FeedbackTitleComponent } from '../feedback-title/feedback-title.component';
import { QuestionItem } from '../../feedback-types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-feedback-radio',
  standalone: true,
  imports: [ReactiveFormsModule, FeedbackTitleComponent],
  templateUrl: './feedback-radio.component.html',
  styleUrl: './feedback-radio.component.css',
})
export class FeedbackRadioComponent implements OnInit, OnDestroy {
  public question = input.required<QuestionItem>();
  protected form!: FormGroup;
  protected additionalInfo = '';
  private isUpdatingValidators = false;
  private valueChangesSubscription!: Subscription;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;

    const control = this.form.get(this.question().title);
    this.valueChangesSubscription = control!.valueChanges.subscribe((value) => {
      if (!this.isUpdatingValidators) {
        // Prevent infinite loop, as setting validators triggers valueChanges
        this.isUpdatingValidators = true;
        this.updateValidators(value, control!);
      }
    });
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription.unsubscribe();
  }

  updateAdditionalInfo(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.additionalInfo = inputElement.value;
    const control = this.form.get(this.question().title);
    const wildcard = this.question().wildcard;
    if (control && wildcard) {
      control.setValue(wildcard.label + ' ' + this.additionalInfo);
    }
  }

  private updateValidators(selectedValue: string, control: AbstractControl): void {
    const wildcard = this.question().wildcard;
    if (wildcard && selectedValue.startsWith(wildcard.label)) {
      control.setValidators([this.customValidator.bind(this)]);
    } else {
      control.clearValidators();
    }
    control.updateValueAndValidity();
    this.isUpdatingValidators = false;
  }

  private customValidator(): ValidationErrors | null {
    const value = this.question().wildcard?.label + ' ' + this.additionalInfo;
    const invalidValue = this.question().wildcard?.label + ' ';
    return value === invalidValue ? { emptyWildcard: true } : null;
  }
}
