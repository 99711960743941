import { Component, OnInit, output } from '@angular/core';
import { CardComponent } from '../../../../components/card/card.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-analytics-form',
  standalone: true,
  imports: [CardComponent, ReactiveFormsModule],
  templateUrl: './analytics-form.component.html',
  styleUrl: './analytics-form.component.css',
})
export class AnalyticsFormComponent implements OnInit {
  public onChange = output<number>();
  protected form!: FormGroup;
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      period: '30',
    });
  }

  onFormChange() {
    this.onChange.emit(Number(this.form.value.period));
  }
}
