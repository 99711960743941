import { Component } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { LinkComponent } from '../link/link.component';
import { filter, map, Observable, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-breadcrumbs',
  standalone: true,
  imports: [IconComponent, RouterLink, LinkComponent, AsyncPipe, ButtonComponent],
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.css',
})
export class BreadcrumbsComponent {
  protected path: Observable<string[]>;
  private navigationHistory: string[] = [];

  constructor(private router: Router) {
    this.path = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      tap((event) => this.navigationHistory.push(event.urlAfterRedirects)),
      map((event) => event.url.split('#')[0].split('/').slice(1)),
    );
  }

  goBack() {
    if (this.navigationHistory.length > 1) {
      this.navigationHistory.pop();
      this.router.navigate([this.navigationHistory.pop()]);
    } else {
      this.router.navigate(['/home']);
    }
  }
}
