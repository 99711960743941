@let q = question();
<h3>{{ q.title }}</h3>
@if (q.subtitle) {
  <p>{{ q.subtitle }}</p>
}
@if (form.get(this.question().title)?.invalid) {
  <div class="error">
    <p>Please select fewer options</p>
  </div>
}
<div [formGroup]="form" class="grid">
  @for (option of q.options; track option) {
    <div class="grid-element">
      <input
        type="checkbox"
        [checked]="form.get(q.title)?.value.includes(option)"
        (change)="toggleOption(option)"
        [id]="q.title + ' ' + option"
      />
      <label [for]="q.title + ' ' + option">{{ option }}</label>
    </div>
  }
  @if (q.wildcard) {
    <div class="grid-element">
      <input
        type="checkbox"
        [checked]="checked"
        (change)="toggleWildcardOption($event)"
        id="{{ q.title }}{{ q.wildcard.label }}"
      />
      <label for="{{ q.title }}{{ q.wildcard.label }}">{{ q.wildcard.label }}</label>
      <input
        type="text"
        id="{{ q.title }}{{ q.wildcard.label }}: more info"
        placeholder="{{ q.wildcard.placeholder }}"
        class="wildcard-input"
        (input)="updateAdditionalInfo($event)"
      />
    </div>
  }
</div>
