<footer>
  <div class="policies">
    <app-link variant="white" href="https://www.agcocorp.com/cookies">Cookie Policy</app-link> |
    <app-link variant="white" href="https://www.agcocorp.com/privacy">Privacy statement</app-link>
  </div>
  <div class="copyright">Copyright &copy; {{ currentYear }} AGCO Corporation. All Rights Reserved.</div>
  <div class="logo">
    <app-link variant="basic" href="https://www.agcocorp.com/"><img src="agco-logo-white.png" alt="logo" /></app-link>
  </div>
</footer>
