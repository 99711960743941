export const SlurryFormData = [
  {
    formControl: 'slurrySet',
    legend: 'Slurry Set',
    options: [
      { label: '10 meter, 30 m³ tank volume', value: '10' },
      { label: '20 meter, 30 m³ tank volume', value: '20' },
      { label: '30 meter, 30 m³ tank volume', value: '30' },
    ],
  },
  {
    formControl: 'applicationRate',
    legend: 'Application Rate',
    options: [
      { label: '10 t/ha', value: '10' },
      { label: '20 t/ha', value: '20' },
      { label: '30 t/ha', value: '30' },
    ],
  },
  {
    formControl: 'variation',
    legend: 'Allowed Variation',
    options: [
      { label: '0%', value: '0' },
      { label: '+/- 10%', value: '1' },
      { label: '+/- 20%', value: '2' },
    ],
  },
];
