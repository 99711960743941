import type { RibbonType } from '../../components/feature-card/feature-card.component';

interface FeatureCard {
  icon: string;
  title: string;
  content: string;
  action: {
    href: string;
    label: string;
  };
  image: string;
  ribbon?: RibbonType;
}

export const featureCards: FeatureCard[] = [
  {
    icon: 'icons/geo-bird.svg',
    title: 'Geo-Bird - Wayline management',
    image: 'images/geo-bird.png',
    content: 'Create optimized waylines for your chosen fields and implements in just a few simple steps.',
    action: {
      href: 'https://app.geo-bird.com',
      label: 'Go to Geo-Bird',
    },
  },
  {
    icon: 'icons/windmill.svg',
    title: 'Public landscape features',
    content:
      'Find public information about infrastructure on your fields, such as windmills, lakes, roads, etc. Download an ISOXML file with the information and get it into you FMIs system of your tractor.',
    image: 'images/OSM.png',
    action: {
      href: '/osm',
      label: 'Go to Public landscaping features',
    },
    ribbon: 'prototype',
  },
  {
    icon: 'icons/path.svg',
    title: 'Soil Compaction',
    content:
      'Minimize soil compaction by optimizing how you drive in the field, which machinery you use and how you configure your tires.',
    image: 'images/soilcompaction.png',
    action: {
      href: '/solgras',
      label: 'Go to Soil compaction',
    },
    ribbon: 'idea',
  },
  {
    icon: 'icons/drop.svg',
    title: 'Slurry optimization',
    content:
      'Optimize the route in the field and adjust application rate for slurry application to minimize unnecessary driving in the field.',
    image: 'images/slurry.png',
    action: {
      href: '/slurry-optimization',
      label: 'Go to Slurry optimization',
    },
    ribbon: 'idea',
  },
  {
    icon: 'icons/calendar.svg',
    title: 'Scheduling',
    content: 'Easy creation of an efficient plan for your field operations.',
    image: 'images/scheduling.png',
    action: {
      href: '/scheduling',
      label: 'Go to Scheduling',
    },
    ribbon: 'idea',
  },
  {
    icon: 'icons/document.svg',
    title: 'FieldView',
    content:
      'Follow up on performance of fields and machinery. Use it for documentation, business decisions and planning of coming seasons.',
    image: 'images/fieldview.png',
    action: {
      href: '/field-view',
      label: 'Go to FieldView',
    },
    ribbon: 'idea',
  },
];
