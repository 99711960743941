import { Component, input } from '@angular/core';
import { CardComponent } from '../../../../components/card/card.component';
import { AnalyticsCardInfoComponent } from '../analytics-card-info/analytics-card-info.component';
import { AnalyticsUsageChartComponent } from './analytics-usage-chart/analytics-usage-chart.component';
import { environment } from '../../../../../environments/environment';
import { LinkComponent } from '../../../../components/link/link.component';
import { PageUsage } from '@shared/interfaces/analytics';

@Component({
  selector: 'app-analytics-page-usage',
  standalone: true,
  imports: [CardComponent, AnalyticsCardInfoComponent, AnalyticsUsageChartComponent, LinkComponent],
  templateUrl: './analytics-page-usage.component.html',
  styleUrl: './analytics-page-usage.component.css',
})
export class AnalyticsPageUsageComponent {
  public period = input<string>();
  public pageUsage = input<PageUsage[]>();
  protected analyticsLink = environment.analytics.link;
}
