import { Component, input, OnInit } from '@angular/core';
import { QuestionItem } from '../../feedback-types';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CheckboxArrayControl } from './feedback-check-block-control';

@Component({
  selector: 'app-feedback-check-block',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './feedback-check-block.component.html',
  styleUrl: './feedback-check-block.component.css',
})
export class FeedbackCheckBlockComponent implements OnInit {
  public question = input.required<QuestionItem>();
  protected form!: FormGroup;
  protected additionalInfo = '';
  private lastAddedOption = '';
  protected checked = false;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit() {
    this.form = this.controlContainer.control as FormGroup;
    this.form.addControl(this.question().title, new CheckboxArrayControl());
    const q = this.question();
    if (q.validators) {
      this.form.get(this.question().title)?.setValidators(q.validators);
    }
  }

  updateAdditionalInfo(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.additionalInfo = inputElement.value;
    this.removeControlValue();
    if (this.checked) {
      this.updateControlValue();
    }
  }

  toggleOption(option: string) {
    const control = this.form.get(this.question().title) as CheckboxArrayControl;
    control.toggleOption(option);
  }

  toggleWildcardOption(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.checked = true;
      this.updateControlValue();
    } else {
      this.checked = false;
      this.removeControlValue();
    }
  }

  private removeControlValue() {
    const control = this.form.get(this.question().title) as CheckboxArrayControl;
    control.removeOption(this.lastAddedOption);
  }

  private updateControlValue() {
    const control = this.form.get(this.question().title) as CheckboxArrayControl;
    const value = this.question().wildcard?.label + ' ' + this.additionalInfo;
    this.lastAddedOption = value;
    control.addOption(value);
  }
}
