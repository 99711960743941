import { ApplicationRef, ComponentRef, createComponent, EmbeddedViewRef, Injectable, Type } from '@angular/core';
import { ModalOptions, ModalServiceInterface } from './modal.service.interface';
import { ModalComponent } from '../../components/modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService implements ModalServiceInterface {
  private wrapperComponentRef?: ComponentRef<ModalComponent>;

  constructor(private appRef: ApplicationRef) {}

  openModal<T extends object>(
    component: Type<T>,
    { confirmModalClose, animationType, data, cancellable, trackOnClose }: ModalOptions<T> = {},
  ) {
    this.wrapperComponentRef = createComponent(ModalComponent, { environmentInjector: this.appRef.injector });
    const modalInstance = this.wrapperComponentRef.instance;
    modalInstance.animation = animationType || 'tada';
    modalInstance.cancellable = cancellable ?? true;
    modalInstance.trackOnClose = trackOnClose;
    modalInstance.confirmModalClose = confirmModalClose ?? false;
    this.appRef.attachView(this.wrapperComponentRef.hostView);
    const domElem = (this.wrapperComponentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    this.wrapperComponentRef.instance.insertComponent(component, data);
  }

  closeModal() {
    if (this.wrapperComponentRef) {
      this.wrapperComponentRef.instance.close();
    }
  }

  // Close without animation, used internally. Use closeModal() instead.
  _closeModal() {
    if (this.wrapperComponentRef) {
      this.appRef.detachView(this.wrapperComponentRef.hostView);
      this.wrapperComponentRef.destroy();
    }
  }
}
