import { ApplicationRef, ComponentRef, createComponent, EmbeddedViewRef, Injectable, Type } from '@angular/core';
import { ModalOptions, ModalServiceInterface } from './modal.service.interface';
import { ModalComponent } from '../../components/modal/modal.component';

interface Modal {
  component: ComponentRef<ModalComponent>;
  onClose?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService implements ModalServiceInterface {
  private modals: Modal[] = [];

  constructor(private appRef: ApplicationRef) {}

  openModal<T extends object>(
    component: Type<T>,
    { confirmModalClose, animationType, data, cancellable, trackOnClose }: ModalOptions<T> = {},
  ) {
    this.modals.push({ component: createComponent(ModalComponent, { environmentInjector: this.appRef.injector }) });
    const wrapperComponentRef = this.modals[this.modals.length - 1].component;
    const modalInstance = wrapperComponentRef.instance;
    modalInstance.animation = animationType || 'tada';
    modalInstance.cancellable = cancellable ?? true;
    modalInstance.trackOnClose = trackOnClose;
    modalInstance.confirmModalClose = confirmModalClose ?? false;
    this.appRef.attachView(wrapperComponentRef.hostView);
    const domElem = (wrapperComponentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    wrapperComponentRef.instance.insertComponent(component, data);
  }

  // Add a function to be called to the most recent modal's onClose event.
  onClose(fn: () => void) {
    this.modals[this.modals.length - 1].onClose = fn;
  }

  closeModal() {
    if (this.modals.length === 0) return;
    const wrapperComponentRef = this.modals[this.modals.length - 1].component;
    wrapperComponentRef.instance.close();
  }

  // Close without animation, used internally. Use closeModal() instead.
  _closeModal() {
    if (this.modals.length === 0) return;
    const index = this.modals.length - 1;
    const { component, onClose } = this.modals[index];
    this.appRef.detachView(component.hostView);
    component.destroy();
    if (onClose) {
      onClose();
    }
    this.modals.pop();
  }
}
