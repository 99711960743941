<h2>{{ title }}</h2>
<p>{{ content }}</p>
<img
  class="close"
  (click)="handleCancel()"
  (keypress)="handleCancel()"
  tabindex="0"
  src="icons/close.svg"
  alt="close"
/>
<div class="actions">
  <app-button variant="primary" (click)="handleCancel()">Continue survey</app-button>
  <app-button variant="outline-primary" (click)="handleConfirm()">Leave</app-button>
</div>
