import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-page',
  standalone: true,
  imports: [NgClass, RouterModule],
  templateUrl: './admin-page.component.html',
  styleUrl: './admin-page.component.css',
})
export class AdminPageComponent implements OnInit, OnDestroy {
  protected active = 'analytics';
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    console.log('AdminPageComponent constructor');
  }

  public ngOnInit(): void {
    const routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e) => {
        console.log('Router event:ddd', e);
        const childRoute = this.route.firstChild;
        const urlSegment = childRoute?.snapshot.url[0]?.path || 'analytics';
        this.active = urlSegment;
      });

    this.subscriptions.push(routerSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  protected setActive(active: string) {
    this.active = active;
    this.router.navigate([active], { relativeTo: this.route });
  }
}
