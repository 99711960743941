import { Component, computed, input } from '@angular/core';
import { GraphComponent } from '../../../../components/graph/graph.component';

@Component({
  selector: 'app-visitors-graph',
  standalone: true,
  imports: [GraphComponent],
  templateUrl: './visitors-graph.component.html',
  styleUrl: './visitors-graph.component.css',
})
export class VisitorsGraphComponent {
  public visitors = input<{ [date: string]: number } | undefined>();
  public data = computed(() => {
    const visitors = this.visitors();
    if (!visitors) return;
    return {
      datasets: [
        {
          data: Object.values(visitors),
          label: 'Visitors',
          backgroundColor: 'rgba(50,151,179,0.2)',
          borderColor: 'rgb(50,151,179)',
          pointBackgroundColor: 'rgb(50,151,179)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(50,151,179,0.8)',
          fill: 'origin',
        },
      ],
      labels: Object.keys(visitors),
    };
  });
}
