import { Component, computed, input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgClass, NgStyle } from '@angular/common';
import { QuestionItem } from '../../feedback-types';

@Component({
  selector: 'app-feedback-radio-block',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, NgStyle],
  templateUrl: './feedback-radio-block.component.html',
  styleUrl: './feedback-radio-block.component.css',
})
export class FeedbackRadioBlockComponent implements OnInit {
  public question = input.required<QuestionItem>();
  protected form!: FormGroup;
  protected values = computed(() => {
    const vals = this.question().values;
    return vals ? vals : ['Strongly agree', 'Agree', 'Neither agree nor disagree', 'Disagree', 'Strongly disagree'];
  });

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }
}
