<app-card class="header">
  <div>
    <app-ribbon type="prototype" />
    <div class="title">Public landscape objects</div>
    <p>Discover objects like windmills, lakes, and more in your fields in just a few steps</p>
    <p>
      With this tool, you can easily identify objects within a field boundary. Simply upload a file with your field
      boundary, and our tool will return public landscape objects in your field, such as windmills, lakes, and roads,
      using data from OpenStreetMap. You can review the found objects and download an updated file with your field and
      the found objects. This file can be uploaded to your farm management system or directly to your tractor's terminal
      for use in the planning and execution of your fieldwork.
    </p>
  </div>
  <div>
    <div>TRY IT OUT</div>
    <ul>
      <li>
        <app-bullet size="small">1</app-bullet> Upload a file containing your field boundary - or use our example field
      </li>
      <li><app-bullet size="small">2</app-bullet> Check the objects identified at the field</li>
      <li>
        <app-bullet size="small">3</app-bullet> Download the file including the objects, and upload it to your terminal
      </li>
    </ul>
    <p class="info-feedback">
      ... and let us know what you think -
      <app-link target="_self" [href]="goToFeedback()">send your feedback</app-link>
    </p>
  </div>
</app-card>
<app-card class="upload">
  <app-card-title
    bullet="1"
    title="select field:"
    subtitle="Upload an ISOXML file (v3 or v4) with a field boundary.
              You can use a ZIP file with the structure TASKDATA/TASKDATA.XML."
  />
  <form class="file-upload" [formGroup]="fileForm" (ngSubmit)="uploadFile()">
    <app-file-upload formControlName="file" accept=".xml,.zip" />
    <app-button type="submit" variant="primary" [disabled]="!fileForm.valid">Find Objects</app-button>
  </form>
  <div class="divider">- or -</div>
  <form [formGroup]="exampleForm" (ngSubmit)="exampleFile()">
    <app-button type="submit" variant="primary">Try our example field</app-button>
  </form>
</app-card>
<app-card class="map">
  <app-card-title
    bullet="2"
    title="Explore objects in the field"
    subtitle="At the map you will see the objects available in the online registry within the field boundary.
              You can Show/Hide different object types in the layer drop down"
  />
  @if (!mapFeatures || mapFeatures === "loading" || mapFeatures === "no-data") {
    <div [ngStyle]="{ height: mapHeight }" class="placeholder">
      @if (!mapFeatures) {
        <app-icon icon="map" />
        <div>Please select a file above</div>
      } @else if (mapFeatures === "loading") {
        <app-icon icon="travel_explore" />
        <div>Loading</div>
      } @else if (mapFeatures === "no-data") {
        <app-icon icon="location_off" />
        <div>No objects found</div>
      }
    </div>
  } @else {
    <app-map [height]="mapHeight" [features]="mapFeatures" />
  }
</app-card>
<app-card class="download">
  <app-card-title
    bullet="3"
    title="Download"
    subtitle="Download an ISOXML file with the field boundary and the objects found in the public registry. You can use this file in your farm management system or tractor terminal."
  />
  <app-button variant="primary" [disabled]="!mapFeatures" (buttonClick)="download()">Download</app-button>
</app-card>
<app-card id="feedback">
  <app-feedback-card feature="Public Landscape Objects" />
</app-card>
