import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';

type ButtonVariant = 'basic' | 'primary' | 'white';

@Component({
  selector: 'app-link',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './link.component.html',
  styleUrl: './link.component.css',
})
export class LinkComponent {
  public href = input<string>();
  public target = input<string>('_blank');
  public routerLink = input<string>();
  public variant = input<ButtonVariant>('primary');

  async externalClick(_: MouseEvent) {
    return this.href() || '';
  }
}
