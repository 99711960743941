<header #header>
  <app-link variant="white" routerLink="/home">Home</app-link>
  <app-link variant="white" routerLink="/home">
    <div class="title">
      <img src="icons/logo.svg" alt="logo" />
      <h1>AGCO Innovation Hub</h1>
    </div>
  </app-link>
  <nav>
    @if (user?.isAdmin) {
      <app-link variant="white" routerLink="/admin">
        <img src="icons/administration.svg" alt="administration" />
      </app-link>
    }
    @if (user) {
      <app-button variant="basic" (buttonClick)="toggleMenu()">
        <img src="icons/profile2.svg" alt="profile" />
      </app-button>
    } @else {
      <app-button variant="basic-white" (buttonClick)="doLogin()">Login</app-button>
    }
  </nav>
  @if (menuOpen) {
    <app-header-menu />
  }
</header>
