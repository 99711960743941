import { Component, input, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonComponent } from '../button/button.component';
import { FeedbackRadioBlockComponent } from './components/feedback-radio-block/feedback-radio-block.component';
import { FeedbackRadioComponent } from './components/feedback-radio/feedback-radio.component';
import { FeedbackIndicatorComponent } from './components/feedback-indicator/feedback-indicator.component';
import { BackendService } from '../../api/bff/backend.service';
import { FeedbackTextAreaComponent } from './components/feedback-text-area/feedback-text-area.component';
import { FeedbackFormData } from './feedback-types';
import { FeedbackCheckBlockComponent } from './components/feedback-check-block/feedback-check-block.component';
import { FeedbackPricingComponent } from './components/feedback-pricing/feedback-pricing.component';
import { FeedbackConsentComponent } from './components/feedback-consent/feedback-consent.component';
import { ModalService } from '../../state/modal/modal.service';
import { FeatureType } from '@shared/interfaces/feedback';
import { firstValueFrom } from 'rxjs';
import { getFeedbackContext } from '../../utils/url/feedback-context';

@Component({
  selector: 'app-feedback-form',
  standalone: true,
  imports: [
    ButtonComponent,
    ReactiveFormsModule,
    FeedbackRadioBlockComponent,
    FeedbackRadioComponent,
    FeedbackIndicatorComponent,
    FeedbackTextAreaComponent,
    FeedbackCheckBlockComponent,
    FeedbackPricingComponent,
    FeedbackConsentComponent,
  ],
  templateUrl: './feedback-form.component.html',
  styleUrl: './feedback-form.component.css',
})
export class FeedbackFormComponent implements OnInit {
  public rating = input.required<number>();
  public formData = input.required<FeedbackFormData>();
  public feature = input.required<FeatureType>();
  protected form!: FormGroup;
  protected activePage = 0;

  constructor(
    private fb: FormBuilder,
    private backend: BackendService,
    private modal: ModalService,
  ) {}

  updatePage(page: number) {
    this.activePage = page;
  }

  ngOnInit() {
    const controllers = this.formData()
      .questions.flat()
      .reduce<{ [key: string]: [null | string | boolean | string[], AbstractControlOptions] }>((acc, q) => {
        const validators = q.validators || [];
        if (q.type === 'radio-block') {
          q.options?.forEach((o) => {
            acc[o] = [null, { validators }];
          });
        } else if (q.type === 'check-block' || q.type === 'consent') {
          // we add the control inside the component
        } else if (q.type === 'pricing') {
          q.options?.forEach((o) => {
            acc[o] = [null, { validators: [...validators, Validators.pattern('[0-9]*')] }];
          });
          acc[q.title] = ['eur', { validators }];
        } else {
          acc[q.title] = [null, { validators }];
        }
        return acc;
      }, {});
    this.form = this.fb.group(controllers);

    this.modal.onClose(() => {
      this.submit();
    });
  }

  close() {
    this.modal.closeModal();
  }

  submitFeedback() {
    this.submit();
  }

  private submit() {
    const consents: string[] = this.form.value.consent || [];
    const feedbackKeys = Object.keys(this.form.value).filter((key) => key !== 'consent');
    firstValueFrom(
      this.backend.postFeedback({
        feature: this.feature(),
        context: getFeedbackContext(),
        rating: this.rating(),
        consent: { hasConsented: !!consents.length, description: consents.join(', ') || '-' },
        feedback: feedbackKeys.map((key) => ({ question: key, answer: this.form.value[key] })),
      }),
    );
  }
}
