import { MetricsData } from '../../components/metrics/metrics.component';

export const stepData = [
  {
    imgSrc: 'images/SC_step1.png',
    altText: 'Step 1',
    heading: '1. John wants to reduce soil compaction',
    paragraph:
      'The field has a wet area, and the farmer considers the best way to drive this field to reduce soil compaction.',
  },
  {
    imgSrc: 'images/SC_step2.png',
    altText: 'Step 2',
    heading: '2. John plans the field operation',
    paragraph:
      'He selects fields and the operation type. He selects machine and implement, and adjusts their settings as needed, e.g. tire configuration.',
  },
  {
    imgSrc: 'images/SC_step3.png',
    altText: 'Step 3',
    heading: '3. John selects a route',
    paragraph:
      'The tool suggests routes optimised by time, fuel consumption and soil compaction. John investigates the suggested routes, and selects the one he wants to use.',
  },
  {
    imgSrc: 'images/SC_step4.png',
    altText: 'Step 4',
    heading: '4. John is ready to go to the field',
    paragraph:
      'In the field he uses the planned route when doing the operation. The route takes the wet area into account, and this way soil compaction is reduced.',
  },
];

export const metricsData: { time: MetricsData[]; compaction: MetricsData[] } = {
  time: [
    {
      label: 'Time',
      value: '1 hr 5 min',
    },
    {
      label: 'Fuel consumption',
      value: '11,4 L',
    },
    {
      label: 'Soil compaction',
      value: '10%',
      indicator: 'error',
    },
    {
      label: 'Risk of soil compaction',
      value: '5%',
      indicator: 'warning',
    },
  ],
  compaction: [
    {
      label: 'Time',
      value: '1 hr 10 min',
    },
    {
      label: 'Fuel consumption',
      value: '12,3 L',
    },
    {
      label: 'Soil compaction',
      value: '4%',
      indicator: 'error',
    },
    {
      label: 'Risk of soil compaction',
      value: '6%',
      indicator: 'warning',
    },
  ],
};
