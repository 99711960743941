import { Component, OnDestroy, OnInit } from '@angular/core';
import { CardComponent } from '../../components/card/card.component';
import { RibbonComponent } from '../../components/ribbon/ribbon.component';
import { ButtonComponent } from '../../components/button/button.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CardTitleComponent } from '../../components/card-title/card-title.component';
import { Subscription } from 'rxjs';
import { SlurryPreviewComponent } from './slurry-preview/slurry-preview.component';
import { SlurryFormData } from './content';
import { FeedbackCardComponent } from '../../components/feedback-card/feedback-card.component';
import { LinkComponent } from '../../components/link/link.component';

@Component({
  selector: 'app-slurry-optimization-page',
  standalone: true,
  imports: [
    CardComponent,
    RibbonComponent,
    ButtonComponent,
    ReactiveFormsModule,
    CardTitleComponent,
    SlurryPreviewComponent,
    FeedbackCardComponent,
    LinkComponent,
  ],
  templateUrl: './slurry-optimization-page.component.html',
  styleUrl: './slurry-optimization-page.component.css',
})
export class SlurryOptimizationPageComponent implements OnInit, OnDestroy {
  public slurryForm!: FormGroup;
  public isPending = false;
  public formData = SlurryFormData;
  public optimizedURL: string | undefined;
  public baseURL: string | undefined;
  private subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.slurryForm = this.fb.group({
      slurrySet: ['10', Validators.required],
      applicationRate: ['10', Validators.required],
      variation: ['0', Validators.required],
    });

    const s = this.slurryForm.valueChanges.subscribe(() => {
      this.isPending = this.slurryForm.valid;
    });
    this.subscriptions.push(s);
    this.calculateOptimizations();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  calculateOptimizations() {
    this.isPending = false;
    const formValue = this.slurryForm.value;
    const v = (Number(formValue.applicationRate) * Number(formValue.variation)) / 10;
    const variation = v === 0 ? '0' : v.toFixed(1);
    const variationString = `[${variation},-${variation}]`;
    this.optimizedURL = `slurry-optimization/${formValue.slurrySet}/${formValue.applicationRate}/${variationString}/optimized/map.html`;
    this.baseURL = `slurry-optimization/${formValue.slurrySet}/${formValue.applicationRate}/not_optimized/map.html`;
  }

  goToFeedback() {
    const location = window.location.href;
    return location.includes('#') ? location : location + '#feedback';
  }
}
