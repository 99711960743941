import { APP_INITIALIZER, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsServiceInterface } from './analytics.service.interface';
import { User } from '@shared/interfaces/user';
import { FeatureType } from '@shared/interfaces/feedback';
import { customEventSeparator } from '@shared/utils/analytics';

interface ExtendedWindow {
  sa_event: (name: string, options: undefined, callback: () => void) => void;
  simpleAnalyticsAddMetadataFunction: (data: { type: string; path: string }) => { [key: string]: string | undefined };
  __karma__?: void;
}
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements AnalyticsServiceInterface {
  private scriptLoaded = false;
  private scriptLoadPromise: Promise<void>;

  constructor() {
    this.scriptLoadPromise = new Promise<void>((resolve, reject) => {
      if (this.isTestEnvironment()) {
        return resolve();
      }
      if (this.scriptLoaded) {
        resolve();
      } else {
        const script = document.createElement('script');
        script.src = environment.analytics.script;
        script.async = true;
        script.defer = true;
        script.setAttribute('data-hostname', environment.analytics.hostName);
        script.setAttribute('data-metadata-collector', 'simpleAnalyticsAddMetadataFunction');
        script.onload = () => {
          this.scriptLoaded = true;
          resolve();
        };
        script.onerror = () => reject(new Error('Failed to load the analytics script'));
        document.body.appendChild(script);
      }
    });
  }

  public isTestEnvironment(): boolean {
    return !!(window as unknown as ExtendedWindow).__karma__;
  }

  public ensureScriptLoaded(): Promise<void> {
    return this.scriptLoadPromise;
  }

  public async trackEvent(feature: FeatureType, eventName: string): Promise<void> {
    await this.ensureScriptLoaded();
    const name = `${feature}${customEventSeparator}${eventName}`;
    await new Promise<void>((resolve) =>
      (window as unknown as ExtendedWindow).sa_event(name, undefined, () => resolve()),
    );
  }

  // Note: SimpleAnalytics does not allow metadata like email addresses, identifiers, or any other type of personal data.
  public setUserData({ country, yearsInIndustry, role, sizeUnit, farmSize, companySize, livestock }: User): void {
    const myAddMetadataFunction = (data: { type: string; path: string }) => {
      if (data.type === 'pageview' || data.type === 'event') {
        return { country, yearsInIndustry, role, sizeUnit, farmSize, companySize, livestock };
      }
      return {};
    };
    this.ensureScriptLoaded().then(() => {
      (window as unknown as ExtendedWindow).simpleAnalyticsAddMetadataFunction = myAddMetadataFunction;
    });
  }
}

export const provideAnalytics = {
  provide: APP_INITIALIZER,
  useFactory: (analyticsService: AnalyticsService) => () => analyticsService.ensureScriptLoaded(),
  deps: [AnalyticsService],
  multi: true,
};
