import { Component } from '@angular/core';
import { LinkComponent } from '../../../components/link/link.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [LinkComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
})
export class FooterComponent {
  protected currentYear: number;

  constructor() {
    this.currentYear = new Date().getFullYear();
  }
}
