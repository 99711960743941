import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { BackendServiceInterface } from './backend.service.interface';
import { FeatureType, Feedback, FeedbackCreate, FeedbackOverview } from '@shared/interfaces/feedback';
import { AnalyticsData } from '@shared/interfaces/analytics';
import { User } from '@shared/interfaces/user';

@Injectable({ providedIn: 'root' })
export class BackendService implements BackendServiceInterface {
  constructor(private httpClient: HttpClient) {}

  getHealth(): Observable<string> {
    return this.httpClient.get<string>(`${environment.backend}/health`);
  }

  getUser(): Observable<User> {
    return this.httpClient.get<User>(`${environment.backend}/user`);
  }

  putUser(user: User): Observable<User> {
    return this.httpClient.put<User>(`${environment.backend}/user`, user);
  }

  getAnalytics(period: number): Observable<AnalyticsData> {
    return this.httpClient.get<AnalyticsData>(`${environment.backend}/analytics/${period}`);
  }

  getFeedback(): Observable<Feedback[]> {
    return this.httpClient.get<Feedback[]>(`${environment.backend}/feedback`);
  }

  getFeedbackOverview(): Observable<FeedbackOverview> {
    return this.httpClient.get<FeedbackOverview>(`${environment.backend}/feedback/overview`);
  }

  getFeedbackByFeature(feature: FeatureType): Observable<Feedback[]> {
    return this.httpClient.get<Feedback[]>(`${environment.backend}/feedback/feature/${feature}`);
  }

  postFeedback(data: FeedbackCreate): Observable<Feedback> {
    return this.httpClient.post<Feedback>(`${environment.backend}/feedback`, data);
  }
}
