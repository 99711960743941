import { Component } from '@angular/core';
import { ButtonComponent } from '../../../components/button/button.component';
import { AuthService } from '../../../state/auth/auth.service';
import { ModalService } from '../../../state/modal/modal.service';
import { ProfileViewComponent } from '../profile-view/profile-view.component';
import { LinkComponent } from '../../../components/link/link.component';

@Component({
  selector: 'app-header-menu',
  standalone: true,
  imports: [ButtonComponent, LinkComponent],
  templateUrl: './header-menu.component.html',
  styleUrl: './header-menu.component.css',
})
export class HeaderMenuComponent {
  constructor(
    private authService: AuthService,
    private modalService: ModalService,
  ) {}

  showProfile() {
    this.modalService.openModal(ProfileViewComponent, { confirmModalClose: true });
  }

  showCookies() {
    if (window.truste && window.truste.util.readyState() === 'complete') {
      window.truste.eu.prefview();
    } else {
      console.error('TrustArc method to show consent preferences is not available.');
    }
  }

  logout() {
    this.authService.signOut();
  }
}
