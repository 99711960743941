import { HttpInterceptorFn } from '@angular/common/http';
import { catchError, from, switchMap, throwError } from 'rxjs';
import { fetchAuthSession } from 'aws-amplify/auth';

let cachedToken: string | null = null;

async function getToken() {
  if (!cachedToken) {
    const session = await fetchAuthSession();
    cachedToken = session.tokens?.idToken?.toString() || null;
  }
  return cachedToken;
}

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  return from(getToken()).pipe(
    switchMap((token) => {
      const authReq = req.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
      return next(authReq);
    }),
    catchError((error) => {
      console.error('Error in authInterceptor:', error);
      return throwError(() => error);
    }),
  );
};
