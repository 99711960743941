import { Component, effect, input } from '@angular/core';
import { InfoHoverComponent } from '../../../../../components/info-hover/info-hover.component';

@Component({
  selector: 'app-response-score',
  standalone: true,
  imports: [InfoHoverComponent],
  templateUrl: './response-score.component.html',
  styleUrl: './response-score.component.css',
})
export class ResponseScoreComponent {
  public score = input.required<number>();
  protected rating!: { path: string; label: string; score: string };

  constructor() {
    effect(() => {
      const s = this.score();
      if (s > 8.76) {
        this.rating = { path: '/rating/VeryLikely.png', label: 'Very likely', score: s.toFixed(1) };
      } else if (s > 6.26) {
        this.rating = { path: '/rating/SomewhatLikely.png', label: 'Somewhat likely', score: s.toFixed(1) };
      } else if (s > 3.76) {
        this.rating = { path: '/rating/Neutral.png', label: 'Neutral', score: s.toFixed(1) };
      } else if (s > 1.26) {
        this.rating = { path: '/rating/SomewhatUnlikely.png', label: 'Somewhat unlikely', score: s.toFixed(1) };
      } else {
        this.rating = { path: '/rating/VeryUnlikely.png', label: 'Very unlikely', score: s.toFixed(1) };
      }
    });
  }
}
