<app-card class="header">
  <div>
    <app-ribbon type="prototype" />
    <div class="title">OSM - "SOME FANCE NAME"</div>
    <p>A short description of the project</p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nec cursus lacus. Mauris vulputate lectus nec
      rhoncus convallis. Duis rhoncus urna eu ex gravida, eu tincidunt libero luctus. Ut pretium justo vel enim
      facilisis, tincidunt faucibus ligula auctor. Donec tortor sem, elementum faucibus augue ut, eleifend gravida
      sapien. Sed in nisi quis nisi efficitur imperdiet.
    </p>
  </div>
  <div>
    <div>TRY IT OUT</div>
    <ul>
      <li><app-bullet size="small">1</app-bullet> Upload an ISOXML file with a field boundary.</li>
      <li><app-bullet size="small">2</app-bullet> Check the objects we found at your field.</li>
      <li><app-bullet size="small">3</app-bullet> Download the file with objects and upload it to your terminal.</li>
    </ul>
    <p class="info-feedback">
      ... and let us know what you think -
      <app-link target="_self" [href]="goToFeedback()">send your feedback</app-link>
    </p>
  </div>
</app-card>
<app-card class="upload">
  <app-card-title bullet="1" title="Upload file:" subtitle="Upload a task file with a field boundary." />
  <form [formGroup]="fileForm" (ngSubmit)="uploadFile()">
    <app-file-upload formControlName="file" accept=".xml,.zip" />
    <app-button type="submit" variant="primary" [disabled]="!fileForm.valid">Find Objects</app-button>
  </form>
</app-card>
<app-card class="map">
  <app-card-title
    bullet="2"
    title="Check the objects at the field"
    subtitle="Show/Hide different object types in the layer drop down"
  />
  @if (!mapFeatures || mapFeatures === "loading" || mapFeatures === "no-data") {
    <div [ngStyle]="{ height: mapHeight }" class="placeholder">
      @if (!mapFeatures) {
        <app-icon icon="map" />
        <div>Please select a file above</div>
      } @else if (mapFeatures === "loading") {
        <app-icon icon="travel_explore" />
        <div>Loading</div>
      } @else if (mapFeatures === "no-data") {
        <app-icon icon="location_off" />
        <div>No objects found</div>
      }
    </div>
  } @else {
    <app-map [height]="mapHeight" [features]="mapFeatures" />
  }
</app-card>
<app-card class="download">
  <app-card-title bullet="3" title="Download" subtitle="Download an ISOXML file with objects from the selected field" />
  <app-button variant="primary" [disabled]="!mapFeatures" (buttonClick)="download()">Download</app-button>
</app-card>
<app-card id="feedback">
  <app-feedback-card feature="OSM" />
</app-card>
