<app-card>
  <form [formGroup]="form" (change)="onFormChange()">
    <fieldset>
      <span><legend>Period:</legend></span>
      <div class="radio-field">
        <input type="radio" id="30 days" name="period" [formControlName]="'period'" value="30" />
        <label for="30 days">Last 30 days</label>
      </div>
      <div class="radio-field">
        <input type="radio" id="365 days" name="period" [formControlName]="'period'" value="365" />
        <label for="365 days">Last 12 months</label>
      </div>
      <div class="radio-field">
        <input type="radio" id="all" name="period" [formControlName]="'period'" value="0" />
        <label for="all">Since launch</label>
      </div>
    </fieldset>
  </form>
</app-card>
