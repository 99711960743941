import { Component, input, OnInit } from '@angular/core';
import { QuestionItem } from '../../feedback-types';
import { ControlContainer, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CheckboxArrayControl } from './feedback-check-block-control';

@Component({
  selector: 'app-feedback-check-block',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './feedback-check-block.component.html',
  styleUrl: './feedback-check-block.component.css',
})
export class FeedbackCheckBlockComponent implements OnInit {
  public question = input.required<QuestionItem>();
  protected form!: FormGroup;
  protected additionalInfo = '';
  private lastAddedOption = '';
  private validators: ValidatorFn[] = [];
  protected checked = false;

  constructor(private controlContainer: ControlContainer) {}

  public ngOnInit() {
    this.form = this.controlContainer.control as FormGroup;
    this.form.addControl(this.question().title, new CheckboxArrayControl());
    const q = this.question();
    this.validators = q.validators || [];
    this.form.get(this.question().title)?.setValidators(this.validators);
  }

  protected getErrors(): string[] {
    const errors = this.form.get(this.question().title)?.errors;
    if (!errors) {
      return [];
    }

    const errorMessages: { [key: string]: string } = {
      required: 'This field is required.',
      maxlength: `Please select fewer options. (Max: ${errors['maxlength']?.requiredLength})`,
      minlength: `Please select more options. (Min: ${errors['minlength']?.requiredLength})`,
      emptyWildcard: ' ', // Custom error message
    };

    return Object.keys(errors).map((errorKey) => errorMessages[errorKey] || 'Unknown error.');
  }

  protected updateAdditionalInfo(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.additionalInfo = inputElement.value;
    this.removeControlValue();
    if (this.checked) {
      this.updateControlValue();
    }
  }

  protected toggleOption(option: string) {
    const control = this.form.get(this.question().title) as CheckboxArrayControl;
    control.toggleOption(option);
  }

  protected toggleWildcardOption(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.checked = true;
      this.updateControlValue();
    } else {
      this.checked = false;
      this.removeControlValue();
    }
  }

  private removeControlValue() {
    const control = this.form.get(this.question().title) as CheckboxArrayControl;
    control.removeOption(this.lastAddedOption);
    control.setValidators(this.validators);
    control.updateValueAndValidity();
  }

  private updateControlValue() {
    const control = this.form.get(this.question().title) as CheckboxArrayControl;
    const value = this.question().wildcard?.label + ' ' + this.additionalInfo;
    this.lastAddedOption = value;
    control.addOption(value);
    control.addValidators(this.customValidator.bind(this));
    control.updateValueAndValidity();
  }

  private customValidator(): ValidationErrors | null {
    const value = this.lastAddedOption;
    const invalidValue = this.question().wildcard?.label + ' ';
    return value === invalidValue ? { emptyWildcard: true } : null;
  }
}
