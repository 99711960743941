<p>In innovationHub features have different maturity levels:</p>
<div class="info-wrapper">
  <div class="info">
    <div class="ribbon"><app-ribbon type="idea" /></div>
    <div>
      <span class="idea">Idea</span> - A concept or description of a solution to a problem we would like to get your
      feedback on.
    </div>
  </div>
  <div class="info">
    <div class="ribbon"><app-ribbon type="prototype" /></div>
    <div>
      <span class="prototype">Prototype</span> - An early model of a solution. Your feedback will help us refine and
      improve the final solution.
    </div>
  </div>
  <div class="info">
    <div class="ribbon"><app-ribbon type="beta" /></div>
    <div>
      <span class="beta">Beta</span> - Working feature ready for testing. Please let us know about your experience using
      it.
    </div>
  </div>
</div>
