@let steps = path | async;
@if (steps && steps.length && steps[0] && steps[0] !== "home") {
  <div class="wrapper">
    <app-button variant="basic" (click)="goBack()">
      <app-icon icon="keyboard_backspace" />
    </app-button>
    <app-link variant="basic" routerLink="/"> HOME </app-link>
    @for (step of steps; track step; let idx = $index) {
      /
      @if (idx === steps.length - 1) {
        <span>{{ step }}</span>
      } @else {
        <app-link variant="basic" [routerLink]="steps.slice(0, idx + 1).join('/')">
          <span>{{ step }}</span>
        </app-link>
      }
    }
  </div>
}
