import { Component, input } from '@angular/core';
import { BulletComponent } from '../bullet/bullet.component';

@Component({
  selector: 'app-card-title',
  standalone: true,
  imports: [BulletComponent],
  templateUrl: './card-title.component.html',
  styleUrl: './card-title.component.css',
})
export class CardTitleComponent {
  public bullet = input<string>();
  public title = input.required<string>();
  public subtitle = input.required<string>();
}
