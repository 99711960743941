import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideCharts } from 'ng2-charts';
import { ChoroplethController, ColorScale, GeoFeature, ProjectionScale } from 'chartjs-chart-geo';

import { routes } from './routing/app.routes';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { withInterceptors } from '@angular/common/http';
import { authInterceptor } from './interceptors/auth.interceptor';
import { provideAnalytics } from './state/analytics/analytics.service';
import { CategoryScale, Filler, LinearScale, LineController, LineElement, PointElement, Tooltip } from 'chart.js';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideCharts({
      registerables: [
        Tooltip,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        LineController,
        Filler,
        ChoroplethController,
        ProjectionScale,
        ColorScale,
        GeoFeature,
      ],
    }),
    provideRouter(routes),
    provideAnimations(),
    provideAnalytics,
  ],
};
