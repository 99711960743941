import { FeedbackFormData } from '../../components/feedback-form/feedback-types';

export const slurryFormData: FeedbackFormData = {
  title: "We'd like to hear some more",
  subtitle: 'We promise it will only take a minute of your time, and your answers are of high value for us',
  questions: [
    [
      {
        title: 'To what extent do you agree with the following statements?',
        type: 'radio-block',
        options: [
          'Using a tool like this will save me time',
          'Slurry application will be easier with this tool',
          'A tool like this will result in less damage of the field',
          'It will be easier for new drivers to do the job',
          'Overall a tool like this will optimize my slurry application',
        ],
      },
      {
        title: "Why would or wouldn't you use a tool like this?",
        subtitle: 'Please add a comment below',
        type: 'textarea',
      },
    ],
    [
      {
        title: 'What do you do when applying slurry today?',
        subtitle: 'Select the answer that best applies to your current practice',
        type: 'radio',
        options: [
          'I never adjust the application rate. After reloading I continue from the spot where I left off.',
          'As I approach the end of the track, I adjust the application rate to finish without running out of material.',
          'While driving I plan two or more tracks ahead and adjust the application rate on the go.',
        ],
      },
    ],
    [
      {
        title: 'How much will you be willing to decrease the application rate?',
        subtitle: 'Select the maximum in percentage that you would allow',
        type: 'radio',
        options: ['0% - I would never decrease the application rate', '5%', '10%', '15%', '20%'],
        wildcard: {
          label: 'More than 20%:',
          placeholder: 'Enter your maximum %',
        },
      },
      {
        title: 'How much will you be willing to increase the application rate?',
        subtitle: 'Select the maximum in percentage that you would allow',
        type: 'radio',
        options: ['0% - I would never increase the application rate', '5%', '10%', '15%', '20%'],
        wildcard: {
          label: 'More than 20%:',
          placeholder: 'Enter your maximum %',
        },
      },
    ],
    [
      {
        title: 'How do you imagine using the output of this tool?',
        subtitle: 'Select one or more answers',
        type: 'check-block',
        options: [
          'Printout: I will print the zones and application rates on paper',
          'App: I will see the zones and application rates in app on my tablet or smartphone',
          'Taskfile: I will download an ISOXML file with zones and application rates, and upload it in the tractor terminal',
        ],
        wildcard: {
          label: 'Other:',
          placeholder: 'Please specify',
        },
      },
      {
        title: 'What would be the biggest challenges for you to start using a tool like this?',
        subtitle: 'Select one or more answers',
        type: 'check-block',
        options: [
          "I don't see any big challenges",
          'I am not used to detailed planning in advance',
          "I don't use guidance lines",
          'I consider it wrong to adjust the application rate',
        ],
        wildcard: {
          label: 'Other:',
          placeholder: 'Please specify',
        },
      },
    ],
    [
      {
        title: 'At what price would you consider a product like this?',
        subtitle:
          "Please add a price in the four categories below, based on what you currently know about the idea - don't worry, you can't do anything wrong.",
        type: 'pricing',
        options: [
          'At what price would you think the price is too low, that the product must be of bad quality? (Too cheap)',
          'At what price would you think the product is a bargain - a great buy for the money? (Cheap/Good value)',
          'At what price would you think the product is not out of the question, but you would have to give it some thoughts? (Expensive)',
          'At what price would you think the price is so expensive that you would not consider buying it? (Too expensive)',
        ],
      },
    ],
    [
      {
        title: "Anything else you'd like to share",
        subtitle: 'Please add add any other comments, ideas and change requests, you would like to share with us',
        type: 'textarea',
      },
      {
        title: 'E-mail contact',
        subtitle:
          'In case we need more information, we would like to contact you on the e-mail address you gave us when signing up.',
        type: 'consent',
        options: ['By ticking this box you allow us to get in contact with you by e-mail.'],
      },
    ],
  ],
};
