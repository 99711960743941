import { Component, effect, input } from '@angular/core';
import { CardComponent } from '../../../../components/card/card.component';
import { AnalyticsCardInfoComponent } from '../analytics-card-info/analytics-card-info.component';
import { LinkComponent } from '../../../../components/link/link.component';
import { TableComponent } from '../../../../components/table/table.component';
import { VisitorsGraphComponent } from './visitors-graph/visitors-graph.component';
import { WorldGraphComponent } from './world-graph/world-graph.component';
import { VisitorsData } from '@shared/interfaces/analytics';
import { environment } from '../../../../../environments/environment';

interface VisitorsByDateTable {
  labels: { date: string; visitors: string };
  data: { date: string; visitors: number }[];
}
interface VisitorsByCountryTable {
  labels: { country: string; visitors: string };
  data: { country: string; visitors: number }[];
}
@Component({
  selector: 'app-analytics-visitors',
  standalone: true,
  imports: [
    CardComponent,
    AnalyticsCardInfoComponent,
    LinkComponent,
    TableComponent,
    VisitorsGraphComponent,
    WorldGraphComponent,
  ],
  templateUrl: './analytics-visitors.component.html',
  styleUrl: './analytics-visitors.component.css',
})
export class AnalyticsVisitorsComponent {
  public period = input<string>();
  public data = input<VisitorsData>();
  protected analyticsLink = environment.analytics.link;
  protected visitorsByDateTable: VisitorsByDateTable | null = null;
  protected visitorsByCountryTable: VisitorsByCountryTable | null = null;
  protected totalVisitors = 0;

  constructor() {
    effect(() => {
      const { byCountry, byDate } = this.data() || { byCountry: [], byDate: [] };

      this.visitorsByDateTable = {
        labels: { date: 'Date', visitors: 'Visitors' },
        data: Object.entries(byDate).map(([date, visitors]) => ({ date, visitors })),
      };
      this.visitorsByCountryTable = {
        labels: { country: 'Country', visitors: 'Visitors' },
        data: Object.entries(byCountry).map(([country, visitors]) => ({ country, visitors })),
      };
      this.totalVisitors = Object.values(byCountry).reduce((acc, visitors) => acc + visitors, 0);
    });
  }
}
