import { NgStyle } from '@angular/common';
import { AfterViewInit, Component, effect, input } from '@angular/core';
import { Control, control, layerGroup, Map, map, tileLayer } from 'leaflet';
import { OSMResponse } from '../../api/portal/portal.service.interface';
import { centerMap, drawLine, drawPoint, drawPolygon, groupByType } from './map-util';

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './map.component.html',
  styleUrl: './map.component.css',
})
export class MapComponent implements AfterViewInit {
  public width = input<string>('100%');
  public height = input<string>('500px');
  public features = input<OSMResponse>();
  private map!: Map;

  private initMap() {
    this.map = map('map', {
      center: [39.8282, -98.5795],
      zoom: 3,
    });

    const tiles = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);
  }

  constructor() {
    effect(() => {
      const features = this.features()?.features;
      if (features) {
        const colors = ['black', 'red', 'blue'];
        const coordinates: [number, number][] = [];
        const layers = {} as Control.LayersObject;
        const types = groupByType(features);
        types.forEach((type, idx) => {
          const color = colors[idx % colors.length];
          const layer = layerGroup([]);
          layers[type[0].properties.type] = layer;
          layer.addTo(this.map);

          type.forEach((feature) => {
            if (feature.geometry.type === 'Point') {
              drawPoint(layer, feature.geometry.coordinates, color);
              coordinates.push(feature.geometry.coordinates);
            } else if (feature.geometry.type === 'LineString') {
              drawLine(layer, feature.geometry.coordinates, color);
              coordinates.push(...feature.geometry.coordinates);
            } else if (feature.geometry.type === 'Polygon') {
              feature.geometry.coordinates.forEach((polygons) => {
                drawPolygon(layer, polygons, color);
                coordinates.push(...polygons);
              });
            } else {
              throw new Error('unknown geometry type');
            }
          });
        });
        control.layers(undefined, layers).addTo(this.map);
        centerMap(this.map, coordinates);
      }
    });
  }

  ngAfterViewInit() {
    this.initMap();
  }
}
