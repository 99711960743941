import { Component } from '@angular/core';
import { CardComponent } from '../../../components/card/card.component';
import { BackendService } from '../../../api/bff/backend.service';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UrlEncodePipe } from '../../../pipes/url-encode.pipe';

@Component({
  selector: 'app-feedback-statistics',
  standalone: true,
  imports: [CardComponent, AsyncPipe, RouterModule, UrlEncodePipe, DecimalPipe],
  templateUrl: './feedback-statistics.component.html',
  styleUrl: './feedback-statistics.component.css',
})
export class FeedbackStatisticsComponent {
  protected feedbackStatistics = this.backend.getFeedbackStatistics();

  constructor(private backend: BackendService) {}
}
