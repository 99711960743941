import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OSMResponse, PortalServiceInterface } from './portal.service.interface';
import { PortalServiceMock } from './portal.service.mock';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PortalService implements PortalServiceInterface {
  constructor(
    private http: HttpClient,
    private mock: PortalServiceMock,
  ) {}

  uploadOSMFile(file: File): Observable<OSMResponse> {
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    if (!fileExtension || (fileExtension !== 'xml' && fileExtension !== 'zip')) {
      throw new Error('Unsupported file type');
    }

    const headers = new HttpHeaders({
      'Content-Type': `application/${fileExtension}`,
    });

    return this.http.post<OSMResponse>(environment.portal.osm, file, { headers });
  }

  downloadOSMFile(file: File): Observable<Blob> {
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    if (!fileExtension || (fileExtension !== 'xml' && fileExtension !== 'zip')) {
      throw new Error('Unsupported file type');
    }

    const headers = new HttpHeaders({
      'Content-Type': `application/${fileExtension}`,
      Accept: `application/zip`,
    });

    return this.http.post<Blob>(environment.portal.osm, file, { headers, responseType: 'blob' as 'json' });
  }
}
