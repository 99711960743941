import { Component, computed, input } from '@angular/core';

export interface TableRow {
  [key: string]: string | number;
}
export interface TableLabels {
  [key: string]: string;
}

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [],
  templateUrl: './table.component.html',
  styleUrl: './table.component.css',
})
export class TableComponent {
  public data = input<TableRow[], TableRow[] | undefined>([], { transform: (v) => v || [] });
  public labels = input<TableLabels, TableLabels | undefined>({}, { transform: (v) => v || {} });
  public columns = computed<string[]>(() => Object.keys(this.labels()));
}
