<div class="eula-wrapper">
  <div class="markdown-wrapper">
    @if (eula) {
      <div class="markdown-text" [innerHtml]="eula"></div>
    } @else {
      <div class="placeholder">...loading</div>
    }
  </div>
  <div class="actions">
    <app-button (click)="acceptEula()" [disabled]="!eula">Accept EULA and continue</app-button>
  </div>
</div>
