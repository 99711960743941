import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { marked } from 'marked';
import { ButtonComponent } from '../../../components/button/button.component';
import { AuthService } from '../../../state/auth/auth.service';

@Component({
  selector: 'app-eula-view',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './eula-view.component.html',
  styleUrl: './eula-view.component.css',
})
export class EulaViewComponent implements OnInit {
  protected eula = '';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.loadEulaText();
  }

  loadEulaText(): void {
    this.http
      .get('eula/v1(2024-07-29_AGCO_SOLUTIONS_EULA).md', { responseType: 'text' })
      .subscribe((data) => (this.eula = marked(data) as string));
  }

  async acceptEula() {
    this.authService.signUp();
  }
}
