@let steps = path | async;
@if (steps && steps.length && steps[0] && steps[0] !== "home") {
  <div class="wrapper">
    <app-link variant="basic" routerLink="/">
      <span><app-icon icon="keyboard_backspace" /> home</span>
    </app-link>
    @for (step of steps; track step; let idx = $index) {
      /
      @if (idx === steps.length - 1) {
        <span>{{ step }}</span>
      } @else {
        <app-link variant="basic" [routerLink]="steps.slice(0, idx + 1).join('/')">
          <span>{{ step }}</span>
        </app-link>
      }
    }
  </div>
}
