import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OSMResponse, PortalServiceInterface } from './portal.service.interface';
import { OSM_RESPONSE_MOCK } from './mock';

@Injectable({
  providedIn: 'root',
})
export class PortalServiceMock implements PortalServiceInterface {
  uploadOSMFile(_: File): Observable<OSMResponse> {
    return of(OSM_RESPONSE_MOCK);
  }

  downloadOSMFile(): Observable<void> {
    return of();
  }
}
