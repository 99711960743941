import { Component, computed, input } from '@angular/core';
import { GraphComponent, lineGraphStyle } from '../../../../../components/graph/graph.component';

@Component({
  selector: 'app-visitors-graph',
  standalone: true,
  imports: [GraphComponent],
  templateUrl: './visitors-graph.component.html',
  styleUrl: './visitors-graph.component.css',
})
export class VisitorsGraphComponent {
  public visitors = input<{ [date: string]: number } | undefined>();
  public data = computed(() => {
    const visitors = this.visitors();
    if (!visitors) return;
    return {
      datasets: [
        {
          data: Object.values(visitors),
          label: 'Visitors',
          ...lineGraphStyle,
        },
      ],
      labels: Object.keys(visitors),
    };
  });
}
