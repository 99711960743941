import languageNames from 'countries-list/minimal/countries.en.min.json';

export const profileViewOptions = {
  countries: Object.values(languageNames).sort(),
  roles: [
    'Farm owner',
    'Farm worker',
    'Contractor, owner',
    'Contractor, worker',
    'Dealer',
    'Agronomist, advisor or consultant',
    'Student',
    'Other',
  ],
  yearsInIndustry: ['Less than 5 years', '5-9 years', '10-19 years', 'More than 19 years'],
  sizeUnits: ['Hectares', 'Acres'],
  farmSizesHectares: [
    'Less than 50 hectares',
    '50-99 hectares',
    '100-299 hectares',
    '300-599 hectares',
    '600-999 hectares',
    '1000-1500 hectares',
    'More than 1500 hectares',
  ],
  farmSizesAcres: [
    'Less than 125 acres',
    '125-244 acres',
    '245-739 acres',
    '740-1479 acres',
    '1480-2469 acres',
    '2470-3700 acres',
    'More than 3700 acres',
  ],
  companySizes: [
    'Less than 5 employees',
    '5-19 employees',
    '20-49 employees',
    '50-99 employees',
    'More than 99 employees',
  ],
  livestockOptions: [
    'No livestock',
    'Cows, milk production',
    'Cows, meat production',
    'Pigs',
    'Hen / chicken',
    'Horses',
    'Other',
  ],
};
