import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

export type RibbonType = 'beta' | 'idea' | 'prototype';

@Component({
  selector: 'app-ribbon',
  standalone: true,
  imports: [NgClass],
  templateUrl: './ribbon.component.html',
  styleUrl: './ribbon.component.css',
})
export class RibbonComponent {
  public type = input.required<RibbonType>();
}
