import { Component, input } from '@angular/core';

export interface Step {
  imgSrc: string;
  heading: string;
  paragraph: string;
}

@Component({
  selector: 'app-steps',
  standalone: true,
  imports: [],
  templateUrl: './steps.component.html',
  styleUrl: './steps.component.css',
})
export class StepsComponent {
  public steps = input.required<Step[]>();
}
