import { Component, ElementRef, Injector, InputSignal, signal, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from '../../state/modal/modal.service';
import { animate, AnimationBuilder, state, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { AnalyticsService } from '../../state/analytics/analytics.service';
import { ConfirmComponent } from '../confirm/confirm.component';
import { customEventSeparator as seperator } from '@shared/utils/analytics';
import { FeatureType } from '@shared/interfaces/feedback';

type ConfirmModalContext = InputSignal<'feedback' | 'user'>;
export type ModalAnimation = 'tada' | 'slideInOut';
@Component({
  animations: [
    // Note: leave animations are set in the close method, so they can be waited for
    trigger('modalAnimation', [
      state('tada', style({ transform: 'translate(-50%, -50%) scale(1)', transformOrigin: 'center' })),
      state('slideInOut', style({ right: 'var(--spacing-xxl)' })),
      transition('* => tada', [
        style({ transform: 'translate(-50%, -50%) scale(0.8)', transformOrigin: 'center' }),
        animate('250ms cubic-bezier(0, 1, 1, 1)', style({ transform: 'translate(-50%, -50%) scale(1)' })),
      ]),
      transition('* => slideInOut', [
        style({ right: '-100%' }),
        animate('500ms cubic-bezier(0, 1, 1, 1)', style({ right: 'var(--spacing-xxl)' })),
      ]),
      transition('slideInOut => void', [animate('500ms cubic-bezier(1, 0, 1, 1)', style({ right: '-100%' }))]),
    ]),
    trigger('backdropFadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms cubic-bezier(.25,.8,.25,1)', style({ opacity: 1 }))]),
    ]),
  ],
  selector: 'app-modal',
  standalone: true,
  imports: [NgClass],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.css',
})
export class ModalComponent {
  @ViewChild('modalContent', { read: ViewContainerRef, static: true }) modalContent!: ViewContainerRef;
  @ViewChild('modal', { static: true }) modalElement!: ElementRef;
  public animation: ModalAnimation = 'tada';
  public cancellable = true;
  public trackOnClose?: string;
  public confirmModalClose = false;
  private compData?: Partial<object & { feature?: () => FeatureType }>;

  constructor(
    private injector: Injector,
    private modalService: ModalService,
    private animationBuilder: AnimationBuilder,
    private analytics: AnalyticsService,
  ) {}

  public insertComponent<T extends object>(component: Type<T>, data?: Partial<T>) {
    const componentRef = this.modalContent.createComponent(component, { injector: this.injector });

    if (data) {
      this.compData = data;
      Object.assign(componentRef.instance, data);
    }

    // Listen to output events if the component is ConfirmComponent
    if (component === ConfirmComponent) {
      (componentRef.instance as ConfirmComponent).onConfirm.subscribe(() => this.handleConfirm());
      (componentRef.instance as ConfirmComponent).onCancel.subscribe(() => this.handleCancel());
    }
  }

  public close() {
    const animation = this.animationBuilder.build([
      style({ opacity: 1 }),
      animate('200ms ease-in', style({ opacity: 0 })),
    ]);

    const player = animation.create(this.modalElement.nativeElement);
    player.onDone(() => {
      this.modalService._closeModal();
    });
    player.play();
  }

  private handleConfirm() {
    this.modalService.closeModal();
    this.close();
    this.track();
  }

  private handleCancel() {
    this.modalService.closeModal();
  }

  public cancel() {
    if (!this.cancellable) return;
    if (this.confirmModalClose) {
      // If confirm is requested from feature, use feature name in tracking else it is from update user modal
      const trackOnClose = this.compData?.feature ? `${this.compData.feature()}${seperator}feedback_cancel` : undefined;
      const c = signal(trackOnClose ? 'feedback' : 'user') as unknown as ConfirmModalContext;
      this.modalService.openModal(ConfirmComponent, { data: { context: c }, cancellable: false, trackOnClose });
    } else {
      this.close();
      this.track();
    }
  }

  private track() {
    if (this.trackOnClose) {
      if (this.trackOnClose.includes(seperator)) {
        const [feature, event] = this.trackOnClose.split(seperator);
        this.analytics.trackEvent(feature as FeatureType, event);
      } else {
        this.analytics.trackEvent('feature not specified', this.trackOnClose);
      }
    }
  }
}
