import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import { signInWithRedirect, signOut, getCurrentUser } from 'aws-amplify/auth';
import { Router } from '@angular/router';
import { amplifyConfig } from '../../utils/amplify/amplify';
import { BackendService } from '../../api/bff/backend.service';
import { firstValueFrom } from 'rxjs';
import { AuthServiceInterface } from './auth.service.interface';
import { User } from '@shared/interfaces/user';
import { ModalService } from '../modal/modal.service';
import { ProfileViewComponent } from '../../layout/components/profile-view/profile-view.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements AuthServiceInterface {
  public user: Promise<User | null>;
  constructor(
    private router: Router,
    private backend: BackendService,
    private modalService: ModalService,
  ) {
    Amplify.configure(amplifyConfig);
    this.user = this.setupUser();
  }

  autoSignIn() {
    return signInWithRedirect();
  }

  signUp() {
    const redirectTo = window.location.origin;
    const signupUrl = `https://${amplifyConfig.Auth.Cognito.loginWith.oauth.domain}/signup?client_id=${amplifyConfig.Auth.Cognito.userPoolClientId}&response_type=code&scope=openid+email&redirect_uri=${redirectTo}`;
    window.location.assign(signupUrl);
  }

  signIn() {
    return signInWithRedirect();
  }

  signOut() {
    this.router.navigate(['/']);
    return signOut({ global: true });
  }

  setUser(user: User) {
    this.user = Promise.resolve(user);
  }

  private ensureProfile(user: User) {
    if (!user.role || !user.yearsInIndustry || !user.country) {
      this.modalService.openModal(ProfileViewComponent, { cancellable: false });
    }
  }

  private setupUser(): Promise<User | null> {
    return new Promise<User | null>((resolve, _) => {
      getCurrentUser()
        .then(() => firstValueFrom(this.backend.getUser()))
        .then((user) => {
          this.ensureProfile(user);
          resolve(user);
        })
        .catch((_err) => {
          // Not logged in, getCurrentUser() will throw an error
          resolve(null);
        });
    });
  }
}
