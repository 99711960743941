import { FeedbackFormData } from '../../components/feedback-form/feedback-types';

export const osmFormData: FeedbackFormData = {
  title: "We'd like to hear some more",
  subtitle: 'We promise it will only take a few minutes of your time, and your answers are of high value for us',
  questions: [
    [
      {
        title: 'To what extent do you agree on the following statements',
        type: 'radio-block',
        options: [
          "During a field operation, it's important to know the location of objects in the field",
          "When planning the field work, it's important to know the location of objects in the field",
          'I find it easy to record objects manually',
          'I believe it is possible to find most of the objects I need in the public registries ',
          "I don't trust the public registries and worry about the accuracy",
          'I want to contribute to the online registry you use for this tool',
        ],
      },
      {
        title: "Why would or wouldn't you use a tool like this?",
        subtitle: 'Please add a comment below',
        type: 'textarea',
      },
    ],
    [
      {
        title: 'How are you currently recording objects in your fields?',
        subtitle: 'Select one or more answers',
        type: 'check-block',
        options: [
          "In the tractor's terminal",
          "On a mobile app when I'm in the field",
          'In a farm management system at the office',
          'I don’t record objects ',
        ],
        wildcard: {
          label: 'Other',
          placeholder: '',
        },
      },
      {
        title: 'How often are you reviewing, editing or deleting your stored objects?',
        subtitle: 'Select the answer that best applies to your current practice',
        type: 'radio',
        options: [
          'Before each operation',
          'Monthly',
          '2-3 times a year',
          'Yearly (e.g. before or after a season)',
          'Never',
        ],
        wildcard: {
          label: 'Other',
          placeholder: '',
        },
      },
    ],
    [
      {
        title: 'How important are the following object types for the planning and execution of your field work?',
        subtitle: 'Select the level of importance for each object',
        type: 'radio-block',
        options: ['Lakes', 'Streams', 'Windmills', 'Power poles', 'Shelter belts', 'Roads', 'Buildings'],
        values: ['Not important', 'Moderately important', 'Very important'],
      },
      {
        title: 'Are there any other object types that are important for your field work?',
        subtitle: 'Please add a comment below',
        type: 'textarea',
      },
    ],
    [
      {
        title: 'Where do you imagine using a tool like this?',
        subtitle: 'Select one or more answers',
        type: 'check-block',
        options: [
          "In the tractor's terminal",
          'Online on a tablet or smartphone',
          'In a farm management system on my computer',
        ],
        wildcard: {
          label: 'Other',
          placeholder: '',
        },
      },
      {
        title:
          "Please indicate for each of the following features whether you consider it a 'Need to have' or a 'Nice to have' for a tool like this?",
        subtitle: 'Select the level of relevancy for each feature',
        type: 'radio-block',
        options: [
          'Cloud connection for easy transfer of objects without a USB stick',
          'Selection of a specific type of object for download',
          'Selection of individual objects for download',
          "Possibility to categorize objects as 'Objects to avoid' or 'Objects for information'",
        ],
        values: ['Need to have', 'Nice to have', 'Not relevant'],
      },
    ],
    [
      {
        title: 'At what price would you consider a product like this?',
        subtitle:
          "Please add a price in the four categories below, based on what you currently know about the idea - don't worry, you can't do anything wrong.",
        type: 'pricing',
        options: [
          'At what price would you think the price is too low, that the product must be of bad quality? (Too cheap)',
          'At what price would you think the product is a bargain - a great buy for the money? (Cheap/Good value)',
          'At what price would you think the product is not out of the question, but you would have to give it some thoughts? (Expensive)',
          'At what price would you think the price is so expensive that you would not consider buying it? (Too expensive)',
        ],
      },
    ],
    [
      {
        title: "Anything else you'd like to share",
        subtitle: 'Please add add any other comments, ideas and change requests, you would like to share with us',
        type: 'textarea',
      },
      {
        title: 'E-mail contact',
        subtitle:
          'In case we need more information, we would like to contact you on the e-mail address you gave us when signing up.',
        type: 'consent',
        options: ['By ticking this box you allow us to get in contact with you by e-mail.'],
      },
    ],
  ],
};
