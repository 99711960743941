<input type="file" class="file-input" (change)="onFileSelected($event)" [accept]="accept()" #fileUpload />

<div
  class="file-upload"
  (click)="fileUpload.click()"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
  (keypress)="fileUpload.click()"
  tabindex="0"
  [ngClass]="{ dragover: isDragOver }"
>
  {{ fileName || "Drag and drop or browse your file" }}
</div>
