<div>
  <div class="tabs">
    <div
      (click)="setActive('analytics')"
      (keyup)="setActive('analytics')"
      tabindex="0"
      [ngClass]="{ active: active === 'analytics' }"
      class="tab"
    >
      Analytics
    </div>
    <div
      (click)="setActive('feedback')"
      (keyup)="setActive('feedback')"
      tabindex="0"
      [ngClass]="{ active: active === 'feedback' }"
      class="tab"
    >
      Feedback
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
