import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AnalyticsService } from '../../state/analytics/analytics.service';
import { externalClick } from '../../utils/url/link-tracker';

type ButtonVariant = 'basic' | 'primary' | 'white';

@Component({
  selector: 'app-link',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './link.component.html',
  styleUrl: './link.component.css',
})
export class LinkComponent {
  public href = input<string>();
  public target = input<string>('_blank');
  public routerLink = input<string>();
  public variant = input<ButtonVariant>('primary');

  constructor(private analytics: AnalyticsService) {}

  async externalClick(e: MouseEvent) {
    return externalClick(e, this.href() || '', this.analytics);
  }
}
