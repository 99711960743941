@let q = question();
<h3>{{ q.title }}</h3>
@if (q.subtitle) {
  <p>{{ q.subtitle }}</p>
}
<div [formGroup]="form" class="grid">
  @for (option of q.options; track option) {
    <div class="grid-element">
      <input
        type="checkbox"
        [checked]="form.get('consent')?.value.includes(option)"
        (change)="toggleOption(option)"
        [id]="q.title + ' ' + option"
      />
      <label [for]="q.title + ' ' + option">{{ option }}</label>
    </div>
  }
</div>
