import { circle, LayerGroup, Map, polygon, polyline } from 'leaflet';
import { FeaturesEntity } from '../../api/portal/portal.service.interface';

type Coordinates = [number, number];
type Layer = Map | LayerGroup;

function reverse(data: Coordinates): Coordinates {
  return data.slice().reverse() as Coordinates;
}

export function groupByType(features: FeaturesEntity[]) {
  const grouped = features.reduce(
    (acc, item) => {
      const type = item.properties.type;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(item);
      return acc;
    },
    {} as { [key: string]: FeaturesEntity[] },
  );

  return Object.values(grouped);
}

export function drawPoint(layer: Layer, coordinates: Coordinates, color: string) {
  const coords = reverse(coordinates);
  const c = circle(coords, { radius: 15, color });
  c.addTo(layer);
}

export function drawLine(layer: Layer, coordinates: Coordinates[], color: string) {
  const values: Coordinates[] = coordinates.map((coords) => reverse(coords));
  const pl = polyline(values, { color });
  pl.addTo(layer);
}

export function drawPolygon(layer: Layer, coordinates: Coordinates[], color: string) {
  const values: Coordinates[] = coordinates.map((coords) => reverse(coords));
  const p = polygon(values, { color });
  p.addTo(layer);
}

export function centerMap(map: Map, coordinates: Coordinates[]) {
  const coords = coordinates.map((c) => reverse(c));
  map.fitBounds(coords);
}
