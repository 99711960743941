import { Component, ElementRef, Injector, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from '../../state/modal/modal.service';
import { animate, AnimationBuilder, state, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { AnalyticsService } from '../../state/analytics/analytics.service';

export type ModalAnimation = 'tada' | 'slideInOut';
@Component({
  animations: [
    // Note: leave animations are set in the close method, so they can be waited for
    trigger('modalAnimation', [
      state('tada', style({ transform: 'translate(-50%, -50%) scale(1)', transformOrigin: 'center' })),
      state('slideInOut', style({ right: 'var(--spacing-xxl)' })),
      transition('* => tada', [
        style({ transform: 'translate(-50%, -50%) scale(0.8)', transformOrigin: 'center' }),
        animate('250ms cubic-bezier(0, 1, 1, 1)', style({ transform: 'translate(-50%, -50%) scale(1)' })),
      ]),
      transition('* => slideInOut', [
        style({ right: '-100%' }),
        animate('500ms cubic-bezier(0, 1, 1, 1)', style({ right: 'var(--spacing-xxl)' })),
      ]),
      transition('slideInOut => void', [animate('500ms cubic-bezier(1, 0, 1, 1)', style({ right: '-100%' }))]),
    ]),
    trigger('backdropFadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms cubic-bezier(.25,.8,.25,1)', style({ opacity: 1 }))]),
    ]),
  ],
  selector: 'app-modal',
  standalone: true,
  imports: [NgClass],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.css',
})
export class ModalComponent {
  @ViewChild('modalContent', { read: ViewContainerRef, static: true }) modalContent!: ViewContainerRef;
  @ViewChild('modal', { static: true }) modalElement!: ElementRef;
  public animation: ModalAnimation = 'tada';
  public cancellable = true;
  public trackOnClose?: string;
  public confirmModalClose = false;

  constructor(
    private injector: Injector,
    private modalService: ModalService,
    private animationBuilder: AnimationBuilder,
    private analytics: AnalyticsService,
  ) {}

  insertComponent<T extends object>(component: Type<T>, data?: Partial<T>) {
    const componentRef = this.modalContent.createComponent(component, { injector: this.injector });

    if (data) {
      Object.assign(componentRef.instance, data);
    }

    // If the component has a public confirmModalClose Promise, we can listen on that
    if ('confirmModalClose' in componentRef.instance) {
      (componentRef.instance.confirmModalClose as Promise<boolean>).then((confirmModalClose) => {
        this.confirmModalClose = confirmModalClose;
      });
    }
  }

  cancel() {
    if (this.cancellable) {
      if (this.confirmModalClose) {
        const confirmed = confirm('Are you sure you want to close without saving?');
        if (confirmed) {
          this.close();
          this.track();
        }
      } else {
        this.close();
        this.track();
      }
    }
  }

  private track() {
    if (this.trackOnClose) {
      this.analytics.trackEvent(this.trackOnClose);
    }
  }

  close() {
    const animation = this.animationBuilder.build([
      style({ opacity: 1 }),
      animate('200ms ease-in', style({ opacity: 0 })),
    ]);

    const player = animation.create(this.modalElement.nativeElement);
    player.onDone(() => {
      this.modalService._closeModal();
    });
    player.play();
  }
}
