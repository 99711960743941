import { Component } from '@angular/core';
import { FeatureCardComponent } from '../../components/feature-card/feature-card.component';
import { featureCards } from './content';
import { CardComponent } from '../../components/card/card.component';
import { FeatureInfoComponent } from './feature-info/feature-info.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { GeneralFeedbackComponent } from '../../components/feedback-fab/components/general-feedback/general-feedback.component';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [FeatureCardComponent, CardComponent, FeatureInfoComponent, WelcomeComponent, GeneralFeedbackComponent],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.css',
})
export class HomePageComponent {
  public featureCards = featureCards;
}
