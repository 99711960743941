@let t = title();
@let st = subtitle();
@if (st) {
  <label [for]="t">
    {{ t }}
    <br /><i>{{ st }}</i>
  </label>
} @else {
  <label [for]="t">{{ t }}</label>
}
