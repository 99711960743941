import { Component, input } from '@angular/core';
import { CardComponent } from '../../../../components/card/card.component';
import { AnalyticsCardInfoComponent } from '../analytics-card-info/analytics-card-info.component';

@Component({
  selector: 'app-analytics-stats',
  standalone: true,
  imports: [CardComponent, AnalyticsCardInfoComponent],
  templateUrl: './analytics-stats.component.html',
  styleUrl: './analytics-stats.component.css',
})
export class AnalyticsStatsComponent {
  public period = input<string>();
  public newUsers = input<number>();
  public totalUsers = input<number>();
}
