<app-card>
  <app-analytics-card-info>
    <div title>Visitors</div>
    <div info>
      A visitor is counted when entering InnovationHub from a URL different from InnovationHub. No cookies or similar
      tracking methods are used. This means if the same user visits InnovationHub again from another URL, it will count
      as two separate visitors.
    </div>
    <div subtitle>{{ period() }}</div>
  </app-analytics-card-info>
  <div class="analytics">
    <div class="countries">
      <div class="info-box">
        <h3>Total visitors in the period:</h3>
        <p class="number">{{ totalVisitors }}</p>
      </div>
      <app-world-graph [visitors]="data()?.byCountry"></app-world-graph>
      <app-table [data]="visitorsByCountryTable?.data" [labels]="visitorsByCountryTable?.labels"></app-table>
    </div>
    <div class="visitors">
      <app-visitors-graph [visitors]="data()?.byDate"></app-visitors-graph>
      <app-table [data]="visitorsByDateTable?.data" [labels]="visitorsByDateTable?.labels"></app-table>
    </div>
    <div class="info">
      More details in <app-link [href]="analyticsLink">Simple Analytics</app-link> (separate login required)
    </div>
  </div>
</app-card>
