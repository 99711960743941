import { Component, ElementRef, ViewChild } from '@angular/core';
import { ButtonComponent } from '../../../components/button/button.component';
import { AuthService } from '../../../state/auth/auth.service';
import { LinkComponent } from '../../../components/link/link.component';
import { User } from '@shared/interfaces/user';
import { HeaderMenuComponent } from '../header-menu/header-menu.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [ButtonComponent, LinkComponent, HeaderMenuComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent {
  protected user?: User;
  protected menuOpen = false;
  @ViewChild('header', { static: true }) eRef!: ElementRef;
  private handleClickOutsideBound: (event: MouseEvent) => void;

  constructor(public authService: AuthService) {
    this.authService.user.then((user) => {
      this.user = user || undefined;
    });
    this.handleClickOutsideBound = this.handleClickOutside.bind(this);
  }

  public doLogin() {
    this.authService.signIn();
  }

  public toggleMenu() {
    this.menuOpen = !this.menuOpen;
    if (this.menuOpen) {
      this.addClickListener();
    } else {
      this.removeClickListener();
    }
  }

  addClickListener() {
    document.addEventListener('click', this.handleClickOutsideBound);
  }

  removeClickListener() {
    document.removeEventListener('click', this.handleClickOutsideBound);
  }

  handleClickOutside(event: MouseEvent) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.menuOpen = false;
      this.removeClickListener();
    }
  }
}
