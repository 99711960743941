import { Component, input, OnInit } from '@angular/core';
import { QuestionItem } from '../../feedback-types';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CheckboxArrayControl } from './feedback-consent.control';

@Component({
  selector: 'app-feedback-consent',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './feedback-consent.component.html',
  styleUrl: './feedback-consent.component.css',
})
export class FeedbackConsentComponent implements OnInit {
  public question = input.required<QuestionItem>();
  protected form!: FormGroup;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit() {
    this.form = this.controlContainer.control as FormGroup;
    if (!this.form.contains('consent')) {
      this.form.addControl('consent', new CheckboxArrayControl());
    }
  }

  toggleOption(option: string) {
    const control = this.form.get('consent') as CheckboxArrayControl;
    control.toggleOption(option);
  }
}
