@let q = question();
<fieldset [formGroup]="form">
  <legend><app-feedback-title [title]="q.title" [subtitle]="q.subtitle" /></legend>
  @for (option of q.options; track option) {
    <div class="radio-element">
      <input type="radio" [value]="option" [formControlName]="q.title" id="{{ q.title }}{{ option }}" />
      <label for="{{ q.title }}{{ option }}">{{ option }}</label>
    </div>
  }
  @if (q.wildcard) {
    <div class="radio-element">
      <input
        type="radio"
        [value]="q.wildcard.label + additionalInfo"
        [formControlName]="q.title"
        [checked]="q.wildcard.label + ' ' + additionalInfo"
        id="{{ q.title }}{{ q.wildcard.label }}"
      />
      <label for="{{ q.title }}{{ q.wildcard.label }}">{{ q.wildcard.label }}</label>
      <input
        type="text"
        id="{{ q.title }}{{ q.wildcard.label }}: more info"
        placeholder="{{ q.wildcard.placeholder }}"
        class="wildcard-input"
        [class.invalid]="form.get(this.question().title)?.errors?.['emptyWildcard']"
        (input)="updateAdditionalInfo($event)"
      />
      @if (form.get(this.question().title)?.errors?.["emptyWildcard"]) {
        <div class="error">This field cannot be empty</div>
      }
    </div>
  }
</fieldset>
