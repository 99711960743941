import { environment } from '../../../environments/environment';

export const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: environment.Auth.userPoolId,
      userPoolClientId: environment.Auth.userPoolClientId,
      identityPoolId: '',
      allowGuestAccess: true,
      signUpVerificationMethod: 'code' as const,
      loginWith: {
        oauth: {
          domain: environment.Auth.domain,
          scopes: ['phone', 'email', 'openid'],
          redirectSignIn: [environment.Auth.redirectSignIn],
          redirectSignOut: [environment.Auth.redirectSignOut],
          responseType: 'code' as const,
        },
      },
    },
  },
};
