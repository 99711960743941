import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-geo-bird-page',
  standalone: true,
  template: '',
})
export class GeoBirdPageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    window.open('https://app.geo-bird.com', '_blank');
    this.router.navigate(['/home']);
  }
}
