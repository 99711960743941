@let q = question();
@let v = values();
<h3>{{ q.title }}</h3>
<div [formGroup]="form" class="grid" [ngStyle]="{ gridTemplateColumns: '1fr repeat(' + v.length + ', 90px)' }">
  <div></div>
  @for (value of v; track value) {
    <div class="header">{{ value }}</div>
  }
  @for (option of q.options; track option; let i = $index) {
    <div class="grid-element" [ngClass]="{ 'no-border': i === 0 }">{{ option }}</div>
    @for (value of v; track value) {
      <div class="grid-element radio" [ngClass]="{ 'no-border': i === 0 }">
        <input type="radio" [value]="value" [formControlName]="option" [id]="q.title + value + i" />
      </div>
    }
  }
</div>
