import { FeedbackFormData } from '../../components/feedback-form/feedback-types';

export const soilCompactionFormData: FeedbackFormData = {
  title: "We'd like to hear some more",
  subtitle: 'We promise it will only take a few minutes of your time, and your answers are of high value for us',
  questions: [
    [
      {
        title: 'To what extent do you agree with the following statements?',
        type: 'radio-block',
        options: [
          'The route you drive in the field can have a high impact on soil compaction',
          'I am willing to plan in advance to minimize soil compaction',
          'Minimizing soil compaction is more important than time and fuel consumption',
          'I will follow a preplanned route in the field that is different from my usual driving pattern, if it can minimize soil compaction',
        ],
      },
      {
        title: "Why would or wouldn't you use a tool like this?",
        subtitle: 'Please add a comment below',
        type: 'textarea',
      },
    ],
    [
      {
        title: 'How are you planning how to drive the field today',
        subtitle: 'Select the answer that best applies to your current practice',
        type: 'radio',
        options: [
          'I plan on the go while driving',
          'I plan in advance by using a map tool, like google maps',
          'I plan in advance by using a tool for field planning',
        ],
        wildcard: {
          label: 'Other',
          placeholder: '',
        },
      },
      {
        title: 'What do you do to minimize soil compaction today?',
        subtitle: 'Select the answer that best applies to your current practice',
        type: 'radio',
        options: [
          'I do nothing',
          'Adjust tire pressure in the morning and/or in the evening',
          'Adjust tire pressure when entering and leaving the field',
          'Adjust tire pressure during the field operation',
        ],
        wildcard: {
          label: 'Other',
          placeholder: '',
        },
      },
    ],
    [
      {
        title: 'For which operation types would you consider using a tool for minimizing soil compaction?',
        subtitle: 'Select one or more answers',
        type: 'check-block',
        options: ['None', 'Tillage', 'Seeding', 'Spraying', 'Slurry application', 'Harvesting'],
        wildcard: {
          label: 'Other',
          placeholder: '',
        },
      },
      {
        title: 'How do you imagine using the output of this tool? ',
        subtitle: 'Select one or more answers',
        type: 'check-block',
        options: [
          'Printed on paper',
          'Online on a tablet or smartphone',
          'I want to see the driving pattern in the tractor’s terminal',
          'I want to follow the preplanned route using the autosteering system in the tractor',
        ],
        wildcard: {
          label: 'Other',
          placeholder: '',
        },
      },
    ],
    [
      {
        title:
          'How much time would you find reasonable to use on setting up a new tool like this? For example, entering master data like machinery, tire configurations etc.',
        subtitle: 'Select the answer that fits best',
        type: 'radio',
        options: [
          'I don’t want to spend time on setting up a new tool',
          '5 minutes',
          '15 minutes',
          '30 minutes',
          '1 hour',
          '> 1 hour',
        ],
      },
      {
        title: "How much time would you spend on planning the routes for tomorrow's operations?",
        subtitle: 'Select one answer',
        type: 'radio',
        options: [
          "I don't want to spend time on planning in advance",
          '1 minute',
          '5 minutes',
          '15 minutes',
          '30 minutes',
          '> 30 minutes',
        ],
      },
    ],
    [
      {
        title: 'At what price would you consider a product like this?',
        subtitle:
          "Please add a price in the four categories below, based on what you currently know about the idea - don't worry, you can't do anything wrong.",
        type: 'pricing',
        options: [
          'At what price would you think the price is too low, that the product must be of bad quality? (Too cheap)',
          'At what price would you think the product is a bargain - a great buy for the money? (Cheap/Good value)',
          'At what price would you think the product is not out of the question, but you would have to give it some thoughts? (Expensive)',
          'At what price would you think the price is so expensive that you would not consider buying it? (Too expensive)',
        ],
      },
    ],
    [
      {
        title: "Anything else you'd like to share",
        subtitle: 'Please add add any other comments, ideas and change requests, you would like to share with us',
        type: 'textarea',
      },
      {
        title: 'E-mail contact',
        subtitle:
          'In case we need more information, we would like to contact you on the e-mail address you gave us when signing up.',
        type: 'consent',
        options: ['By ticking this box you allow us to get in contact with you by e-mail.'],
      },
    ],
  ],
};
