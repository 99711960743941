import { FeedbackFormData } from '../../components/feedback-form/feedback-types';
import { maxArrayLength } from '../../utils/validators/maxArrayLength';

export const schedulingFormData: FeedbackFormData = {
  title: "We'd like to hear some more",
  subtitle: 'We promise it will only take a minute of your time, and your answers are of high value for us',
  questions: [
    [
      {
        title: 'To what extent do you agree with the following statements?',
        type: 'radio-block',
        options: [
          'I always know what the most efficient order to operate my fields is',
          'It can be stressful to decide what the best order to operate my fields is',
          'It can be stressful if I need to reschedule during the day ',
          'It is necessary to create an efficient plan, so I will finish the field operations on time',
          'It is important to minimize the time we spend on the road ',
        ],
      },
      {
        title: "Why would or wouldn't you use a tool like this?",
        subtitle: 'Please add a comment below',
        type: 'textarea',
      },
    ],
    [
      {
        title: 'When do you plan which field to operate in which order at your farm',
        subtitle: 'Select the answer that best fits your current practice',
        type: 'radio',
        options: [
          'We plan on-the-go, when finishing one field we decide where to go next',
          'We plan the order of fields in the morning or the evening before',
          'We plan 2-3 days in advance',
          'We create a preliminary plan 1 week ahead, and adjust it as the operation date approaches',
        ],
      },
      {
        title: 'How are you planning your field operations currently? ',
        subtitle: 'Select the answer that best fits your current practice',
        type: 'radio',
        options: [
          'Planning occurs in my head',
          'I use paper or a whiteboard to do the planning',
          'I use an office application (Excel, Word or similar) for planning',
          'I use a field management system for planning',
          "I'm not the one planning at our farm",
        ],
        wildcard: {
          label: 'Other',
          placeholder: '',
        },
      },
    ],
    [
      {
        title:
          'For which operation types would you consider using a tool to find the most efficient order to operate your fields?',
        subtitle: 'Select one or more answers',
        type: 'check-block',
        options: ['None', 'Tillage', 'Seeding', 'Spraying', 'Fertilizing', 'Slurry application', 'Harvesting'],
        wildcard: {
          label: 'Other',
          placeholder: '',
        },
      },
      {
        title: 'Who is a scheduling tool relevant for, and how would they use it?',
        type: 'radio-block',
        options: ['Owner', 'Manager', 'Driver', 'Advisor'],
        values: ['Creating the plan', 'Viewing the plan', 'Both creation and viewing', 'Not relevant'],
      },
    ],
    [
      {
        title:
          'Which of the following inputs do you consider most essential for a scheduling tool to take into account?',
        subtitle: 'Select up to 5 options that you consider the most important',
        type: 'check-block',
        options: [
          'Weather forecast',
          'Transport time on road',
          'Idle time',
          'Travel distance/fuel consumption',
          'Crop type',
          'Crop conditions',
          'Soil type',
          'Soil moisture',
          'Available drivers',
          'Next operation at the field',
          'Location for unloading or refilling material',
          'Field entry/exit points',
        ],
        wildcard: {
          label: 'Other',
          placeholder: '',
        },
        validators: [maxArrayLength(5)],
      },
    ],
    [
      {
        title: 'How do you imagine using the output of this tool?',
        type: 'radio',
        options: ['Printed on paper', 'Online on a tablet or smartphone', 'In the machine terminal'],
        wildcard: {
          label: 'Other',
          placeholder: '',
        },
      },
      {
        title: 'In case you need to reschedule, what would you expect to do if you had a tool like this? ',
        subtitle: 'Select the answer that best fits your expectations',
        type: 'radio',
        options: [
          'I would decide where to go next on-the-go (continue without rescheduling)',
          'I would manually select the remaining fields and use the tool for rescheduling',
          'I would expect that the remaining fields are preselected, and I would just have to reschedule',
          'I would expect the plan is always up to date according to my progress',
        ],
      },
    ],
    [
      {
        title: 'At what price would you consider a product like this?',
        subtitle:
          "Please add a price in the four categories below, based on what you currently know about the idea - don't worry, you can't do anything wrong.",
        type: 'pricing',
        options: [
          'At what price would you think the price is too low, that the product must be of bad quality? (Too cheap)',
          'At what price would you think the product is a bargain - a great buy for the money? (Cheap/Good value)',
          'At what price would you think the product is not out of the question, but you would have to give it some thoughts? (Expensive)',
          'At what price would you think the price is so expensive that you would not consider buying it? (Too expensive)',
        ],
      },
    ],
    [
      {
        title: "Anything else you'd like to share",
        subtitle: 'Please add add any other comments, ideas and change requests, you would like to share with us',
        type: 'textarea',
      },
      {
        title: 'E-mail contact',
        subtitle:
          'In case we need more information, we would like to contact you on the e-mail address you gave us when signing up.',
        type: 'consent',
        options: ['By ticking this box you allow us to get in contact with you by e-mail.'],
      },
    ],
  ],
};
