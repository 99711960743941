import { Component, input } from '@angular/core';

export type BulletSize = 'small' | 'medium';

@Component({
  selector: 'app-bullet',
  standalone: true,
  imports: [],
  templateUrl: './bullet.component.html',
  styleUrl: './bullet.component.css',
})
export class BulletComponent {
  public size = input<BulletSize>('medium');
}
