import { Component, computed, effect, input, Signal } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IconComponent } from '../../../components/icon/icon.component';
import { NgStyle } from '@angular/common';
import { MetricsComponent } from '../../../components/metrics/metrics.component';

interface KPIData {
  label: string;
  value: string;
}
interface TreatmentData {
  color: string;
  tank: string;
  rate: string;
  adjustment: string;
  usage: string;
}

@Component({
  selector: 'app-slurry-preview',
  standalone: true,
  imports: [IconComponent, NgStyle, MetricsComponent],
  templateUrl: './slurry-preview.component.html',
  styleUrl: './slurry-preview.component.css',
})
export class SlurryPreviewComponent {
  public url = input<string>();
  protected safeUrl: Signal<SafeResourceUrl> = computed(() =>
    this.sanitizer.bypassSecurityTrustResourceUrl(this.url() || ''),
  );
  protected kpis: KPIData[] = [];
  protected treatments: TreatmentData[] = [];
  protected isLoaded = false;

  constructor(private sanitizer: DomSanitizer) {
    effect(() => {
      this.url();
      this.kpis = [];
      this.treatments = [];
      this.isLoaded = false;
    });
  }

  handleLoad(iframe: HTMLIFrameElement) {
    const iframeDocument = iframe.contentWindow?.document;
    if (!iframeDocument || !iframeDocument.body) return;
    const groups = Array.from(iframeDocument.body.querySelectorAll(':scope > div'));
    if (groups.length !== 3) return;

    // Hide legends on map
    groups[0].setAttribute('style', 'display: none');
    groups[1].setAttribute('style', 'display: none');

    // KPIs
    const kpiLiEls = groups[1].querySelectorAll('ul > li');
    const kpiContent = Array.from(kpiLiEls).map((li) => li.textContent?.trim() || '');
    const index = kpiContent.findIndex((el) => el === 'Application levels area:') || kpiContent.length;
    kpiContent.splice(index, kpiContent.length - index); // Remove Application level data
    this.kpis = kpiContent
      .map((c) => {
        const [label, rawValue] = c.split(':').map((s) => s.trim());
        const [number, unit] = rawValue.split(' ').map((s) => s.trim());
        const value = unit ? `${Math.round(parseFloat(number))} ${unit}` : `${Math.round(parseFloat(number))}`;
        return { label, value };
      })
      .filter((kpi) => kpi.label !== 'Distance driven');

    // Treatment
    const treatmentLiEls = groups[0].querySelectorAll('ul > li');
    this.treatments = Array.from(treatmentLiEls).map((t) => {
      // E.g. "G9, 10.0t/ha (0%), tank usage 27%"
      const color = (t.querySelector('i')?.getAttribute('style') || '').split(':')[1];
      const c = t.textContent?.trim() || '';
      const [tank, application, labelRaw] = c.split(',').map((s) => s.trim());
      const [rawRate, rawAdjustment] = application.split(' ').map((s) => s.trim());
      const rate = `${rawRate.slice(0, -4)} ${rawRate.slice(-4)}`; // E.g. "10.0t/ha" -> "10.0 t/ha"
      const adjustment = rawAdjustment.replace('(', '').replace(')', '');
      const usage = labelRaw.replace('tank usage ', '');
      return { color, tank, rate, adjustment, usage };
    });

    this.isLoaded = true;
  }
}
