import { Component, input, OnInit } from '@angular/core';
import { ButtonComponent } from '../../../button/button.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { BackendService } from '../../../../api/bff/backend.service';
import { getFeedbackContext } from '../../../../utils/url/feedback-context';
import { NgStyle } from '@angular/common';
import { ModalService } from '../../../../state/modal/modal.service';

@Component({
  selector: 'app-general-feedback',
  standalone: true,
  imports: [ButtonComponent, ReactiveFormsModule, NgStyle],
  templateUrl: './general-feedback.component.html',
  styleUrl: './general-feedback.component.css',
})
export class GeneralFeedbackComponent implements OnInit {
  public showCloseButton = input(false);
  public form!: FormGroup;
  protected formControlName = 'Tell us what you think';
  protected submitted = false;

  constructor(
    private fb: FormBuilder,
    private backend: BackendService,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      [this.formControlName]: [null, Validators.required],
      consent: null,
    });
  }

  submitFeedback() {
    this.submitted = true;
    const consent = this.form.value.consent
      ? {
          hasConsented: true,
          description: 'By ticking this box you allow us to get in contact with you by e-mail.',
        }
      : { hasConsented: false, description: '' };
    firstValueFrom(
      this.backend.postFeedback({
        feature: 'General',
        context: getFeedbackContext(),
        rating: -1,
        consent,
        feedback: [
          {
            question: this.formControlName,
            answer: this.form.value[this.formControlName],
          },
        ],
      }),
    );
    this.form.reset();
  }

  closeModal() {
    this.modalService.closeModal();
  }
}
