import { Component, computed, input } from '@angular/core';
import { GraphComponent } from '../../../../../components/graph/graph.component';
import { Scale } from 'chart.js';

@Component({
  selector: 'app-analytics-bar-chart',
  standalone: true,
  imports: [GraphComponent],
  templateUrl: './analytics-bar-chart.component.html',
  styleUrl: './analytics-bar-chart.component.css',
})
export class AnalyticsBarChartComponent {
  public data = input<{ [date: string]: number } | undefined>();
  public title = input<string>();
  protected graphData = computed(() => {
    const dataInput = this.data();
    if (!dataInput) return;
    const dataValues = Object.values(dataInput);
    return {
      datasets: [
        {
          data: dataValues,
          label: 'Visitors',
          backgroundColor: dataValues.map((_, i) => this.getColor(i)),
        },
      ],
      labels: Object.keys(dataInput),
    };
  });

  public options = computed(() => ({
    maintainAspectRatio: false,
    scales: {
      y: {
        afterFit: (scale: Scale) => {
          scale.width = 50;
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: this.title(),
        align: 'start' as const,
      },
    },
  }));

  private getColor(index: number): string {
    const hue = (index * 137.508) % 360; // Use golden angle approximation
    return `hsla(${hue}, 70%, 50%, 0.65)`;
  }
}
