<h2 class="profile-header">User Profile</h2>
<p>We would like to get to know you.</p>
@if (form) {
  <form [formGroup]="form" (ngSubmit)="updateProfile()">
    <div class="element">
      <label class="label" for="country">Where do you live?</label>
      <select id="country" formControlName="country">
        @for (country of options.countries; track country) {
          <option [value]="country">{{ country }}</option>
        }
      </select>
    </div>
    <div class="element">
      <label class="label" for="yearsInIndustry">How many years have you worked in the agriculture industry?</label>
      <select id="yearsInIndustry" formControlName="yearsInIndustry">
        @for (yearsInIndustry of options.yearsInIndustry; track yearsInIndustry) {
          <option [value]="yearsInIndustry">{{ yearsInIndustry }}</option>
        }
      </select>
    </div>
    <div class="element">
      <label class="label" for="role">What is your role?</label>
      <select id="role" formControlName="role">
        @for (role of options.roles; track role) {
          <option [value]="role">{{ role }}</option>
        }
      </select>
    </div>
    @if (form.get("role")?.value === "Farm owner" || form.get("role")?.value === "Farm worker") {
      <div class="element">
        <div class="label">What is the size of your farm?</div>
        <div class="radio-block">
          <span>Show in: </span>
          @for (size of options.sizeUnits; track size) {
            <label> <input type="radio" formControlName="sizeUnit" value="{{ size }}" /> {{ size }}</label>
          }
        </div>
        <select formControlName="farmSize">
          @let farmSizeData =
            form.get("sizeUnit")?.value === "Hectares" ? options.farmSizesHectares : options.farmSizesAcres;
          @for (size of farmSizeData; let idx = $index; track idx) {
            <option [value]="size">{{ size }}</option>
          }
        </select>
      </div>
      <div class="element">
        <label class="label" for="livestock">Does your farm have any livestock?</label>
        <select id="livestock" formControlName="livestock">
          @for (option of options.livestockOptions; track option) {
            <option [value]="option">{{ option }}</option>
          }
        </select>
      </div>
    }
    @if (form.get("role")?.value === "Contractor, owner" || form.get("role")?.value === "Contractor, worker") {
      <div class="element">
        <label class="label" for="companySize">What is the size of your company?</label>
        <select id="companySize" formControlName="companySize">
          @for (size of options.companySizes; track size) {
            <option [value]="size">{{ size }}</option>
          }
        </select>
      </div>
    }
    @if (form.get("role")?.value === "Dealer" || form.get("role")?.value === "Agronomist, advisor or consultant") {
      <div class="element">
        <label class="label" for="companyName">What is the name of your company?</label>
        <input id="companyName" type="text" formControlName="companyName" />
      </div>
    }
    <app-button type="submit" variant="primary" [disabled]="form.invalid">Save</app-button>
  </form>
}
