import { Component, effect, input, output } from '@angular/core';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-confirm',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './confirm.component.html',
  styleUrl: './confirm.component.css',
})
export class ConfirmComponent {
  public context = input.required<'feedback' | 'user'>();
  protected title = '';
  protected content = '';
  public onConfirm = output<void>();
  public onCancel = output<void>();

  constructor() {
    effect(() => {
      if (this.context() === 'feedback') {
        this.title = 'Leave survey?';
        this.content =
          'When leaving the survey, your answers will be saved, and you will no longer be able to change your answers.';
      } else {
        this.title = 'Discard changes?';
        this.content = 'When you leave without saving, your changes will be discarded.';
      }
    });
  }

  protected handleConfirm() {
    this.onConfirm.emit();
  }

  protected handleCancel() {
    this.onCancel.emit();
  }
}
