import { Component, forwardRef, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [NgClass],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.css',
})
export class FileUploadComponent implements ControlValueAccessor {
  public accept = input('*');

  public fileName = '';
  public isDragOver = false;
  private onChange: (file: File | null) => void = () => {
    // Replaced with actual implementation in registerOnChange
  };
  private onTouched: () => void = () => {
    // Replace with actual implementation in registerOnTouched
  };

  writeValue(file: File | null): void {
    this.fileName = file?.name || '';
  }

  registerOnChange(fn: (file: File | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(_: boolean): void {
    // Not implemented
  }

  handleFile(file: File) {
    this.fileName = file.name;
    this.onChange(file);
    this.onTouched();
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      this.handleFile(input.files[0]);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;

    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      this.handleFile(event.dataTransfer.files[0]);

      // Clear the input element to allow re-selecting the same file
      // const input = document.querySelector('.file-input') as HTMLInputElement;
      // if (input) {
      //   input.value = '';
      // }
    }
  }
}
