import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-info-hover',
  standalone: true,
  imports: [],
  templateUrl: './info-hover.component.html',
  styleUrl: './info-hover.component.css',
})
export class InfoHoverComponent {
  protected isVisible = false;

  @HostListener('mouseover', ['$event'])
  onMouseOver(_: MouseEvent): void {
    this.isVisible = true;
  }

  @HostListener('mouseout')
  onMouseOut(): void {
    this.isVisible = false;
  }
}
